// @flow

import { Dispatch } from 'redux';
import type { Action, GetState } from '../types/actions';
import { get, post, put, del } from '../utils/rsaaHelpers';
import history from '../history';
import { stripNonNumberForSubmit } from '../utils/formattingHelpers';

export const INCOME_GET_INCOME_BEGIN = 'INCOME_GET_INCOME_BEGIN';
export const INCOME_GET_INCOME_SUCCESS = 'INCOME_GET_INCOME_SUCCESS';
export const INCOME_GET_INCOME_FAILURE = 'INCOME_GET_INCOME_FAILURE';

export const INCOME_ADD_INCOME_BEGIN = 'INCOME_ADD_INCOME_BEGIN';
export const INCOME_ADD_INCOME_SUCCESS = 'INCOME_ADD_INCOME_SUCCESS';
export const INCOME_ADD_INCOME_FAILURE = 'INCOME_ADD_INCOME_FAILURE';

export const INCOME_EDIT_SET_STATE = 'INCOME_EDIT_SET_STATE';

export const INCOME_EDIT_INCOME_BEGIN = 'INCOME_EDIT_INCOME_BEGIN';
export const INCOME_EDIT_INCOME_SUCCESS = 'INCOME_EDIT_INCOME_SUCCESS';
export const INCOME_EDIT_INCOME_FAILURE = 'INCOME_EDIT_INCOME_FAILURE';

export const INCOME_DELETE_INCOME_BEGIN = 'INCOME_DELETE_INCOME_BEGIN';
export const INCOME_DELETE_INCOME_SUCCESS = 'INCOME_DELETE_INCOME_SUCCESS';
export const INCOME_DELETE_INCOME_FAILURE = 'INCOME_DELETE_INCOME_FAILURE';

// Tracks fields as they are entered
export const INCOME_SET_ADD_FIELD = 'INCOME_SET_ADD_FIELD';

export const incomeGetIncome = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( get(
		`/accounts/${activeAccount}/incomes`,
		[ INCOME_GET_INCOME_BEGIN, INCOME_GET_INCOME_SUCCESS, INCOME_GET_INCOME_FAILURE ]
	) );
};

export const incomeAddIncome = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().income;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( post(
		`/accounts/${activeAccount}/incomes`,
		[ INCOME_ADD_INCOME_BEGIN, INCOME_ADD_INCOME_SUCCESS, INCOME_ADD_INCOME_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
			amount: stripNonNumberForSubmit( add.amount ),
		}
	) ).then( () => {
		const { errors } = getState().income;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/income' );
		}
	})
};

export const incomeSetStateForEdit = ( id: number, state: {} ) : Action => {
	return {
		type: INCOME_EDIT_SET_STATE,
		payload: {
			id,
			state
		}
	}
};

export const incomeEditIncome = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().income;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( put(
		`/accounts/${activeAccount}/incomes/${id}`,
		[ INCOME_EDIT_INCOME_BEGIN, INCOME_EDIT_INCOME_SUCCESS, INCOME_EDIT_INCOME_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
			amount: stripNonNumberForSubmit( add.amount ),
			gross_income: stripNonNumberForSubmit( add.gross_income ),
		}
	) ).then( () => {
		const { errors } = getState().income;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/income' );
		}
	});
};

export const incomeDeleteIncome = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( del(
		`/accounts/${activeAccount}/incomes/${id}`,
		[ INCOME_DELETE_INCOME_BEGIN, INCOME_DELETE_INCOME_SUCCESS, INCOME_DELETE_INCOME_FAILURE ],
		{},
		{id}
	) );
};

export const incomeSetField = ( field: string, value: any ) => {
	return {
		type: INCOME_SET_ADD_FIELD,
		payload: {
			field,
			value
		}
	}
};

