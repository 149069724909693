// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {ExpensesState, IncomeState, LoansState, State} from '../../types/state';
import { returnSubsetById } from '../../utils/stateHelpers';
import { getPeriodEquivalent } from '../../utils/frequencyHelpers';
import { incomeGetIncome } from '../../actions/income';

// Components
import { SpentChart } from './spentChart';
import { organizeExpenses } from '../../utils/objectHelpers';
import { TotalIncomeLevel } from '../income/totalIncomeLevel';
import { TotalExpensesLevel } from '../expenses/totalExpensesLevel';
import { IncomeRemainingLevel } from '../income/incomeRemainingLevel';
import { PercentIncomeRemainingLevel } from '../income/percentIncomeRemainingLevel';
import { expensesGetExpenses } from '../../actions/expenses';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import {TotalLoansLevel} from "../loans/totalLoansLevel"
import {loansGetLoans} from "../../actions/loans"
import {TotalGrossIncomeLevel} from "../income/totalGrossIncomeLevel"
import {formatPercent, formatUSD} from "../../utils/formattingHelpers"
import {DTIChart} from "./dtiChart"

type Props = {
	income: IncomeState;
	expenses: ExpensesState;
	loans: LoansState;
	incomeGetIncome: typeof incomeGetIncome;
	expensesGetExpenses: typeof expensesGetExpenses;
	loansGetLoans: typeof loansGetLoans;
	period: string;
};

class Summary extends Component<Props> {

	componentWillMount() {
		this.props.incomeGetIncome();
		this.props.expensesGetExpenses();
		this.props.loansGetLoans();
	}

	// @todo there is now a similar helper for computing this in frequencyHelpers
	getTotalIncome = () : number => {
		let totalIncome = 0.0000;
		let incomes = returnSubsetById( this.props.income.ids, this.props.income.objects );

		incomes.forEach( income => {
			totalIncome += getPeriodEquivalent( income, this.props.period );
		});

		return totalIncome;
	};

	getTotalGrossIncome = () : number => {
		let totalIncome = 0.0000;
		let incomes = returnSubsetById( this.props.income.ids, this.props.income.objects );

		incomes.forEach( income => {
			const obj = {
				amount: income.gross_income,
				frequency: income.frequency,
			};
			totalIncome += getPeriodEquivalent( obj, this.props.period );
		});

		return totalIncome;
	};

	// @todo there is now a similar helper for computing this in frequencyHelpers
	getTotalExpenses = () : number => {
		// Probably see menu grouping example
		let totalExpenses = 0.0000;
		let expenses = organizeExpenses( returnSubsetById( this.props.expenses.ids, this.props.expenses.objects ) );

		expenses.forEach( expense => {
			totalExpenses += getPeriodEquivalent( expense, this.props.period );
		});

		return totalExpenses;
	};

	getMinimumLoans = () : number => {
		let totalLoans = 0.0000;
		let loans = returnSubsetById( this.props.loans.ids, this.props.loans.objects );

		loans.forEach( loan => {
			const totalObj = {
				amount: parseFloat(loan.minimum_payment),
				frequency: loan.frequency,
			};
			totalLoans += getPeriodEquivalent(totalObj, this.props.period)
		});

		return totalLoans;
	};

	getExtraLoans = () : number => {
		let totalLoans = 0.0000;
		let loans = returnSubsetById( this.props.loans.ids, this.props.loans.objects );

		loans.forEach( loan => {
			const totalObj = {
				amount: parseFloat(loan.extra_payment),
				frequency: loan.frequency,
			};
			totalLoans += getPeriodEquivalent(totalObj, this.props.period)
		});

		return totalLoans;
	};

	render() {
		let totalIncome = this.getTotalIncome(),
			totalGrossIncome = this.getTotalGrossIncome(),
			totalExpenses = this.getTotalExpenses(),
			minimumLoans = this.getMinimumLoans(),
			extraLoans = this.getExtraLoans(),
			totalLoans = minimumLoans + extraLoans,
			dtiGross = (minimumLoans) / totalGrossIncome * 100,
			dtiNet = (minimumLoans) / totalIncome * 100,
			dtiMaxAmount = totalGrossIncome * .43,
			dtiMaxLeft = dtiMaxAmount-minimumLoans;

		return (
			<ConnectedSectionWrapper
				title={"Budget Summary"}
				periodToggle={true}
				render={() => (
					<>
						<div className="columns">
							<div className="column is-two-thirds">
								<div className="level">
									<TotalIncomeLevel totalIncome={totalIncome}/>
									<TotalGrossIncomeLevel totalIncome={totalGrossIncome}/>
								</div>

								<div className="level">
									<TotalExpensesLevel totalExpenses={totalExpenses}/>
									<TotalLoansLevel label="Minimum Loan Payments" color="warning-dark" totalLoans={minimumLoans}/>
									<TotalLoansLevel label="Extra Loan Payments" color="info-dark" totalLoans={extraLoans}/>
								</div>

								<div className="level">
									<IncomeRemainingLevel totalIncome={totalIncome} totalExpenses={totalExpenses+totalLoans}/>
									<PercentIncomeRemainingLevel totalIncome={totalIncome} totalExpenses={totalExpenses} minimumLoans={minimumLoans} extraLoans={extraLoans}/>
									<IncomeRemainingLevel label="Min. Payments Only" color="info-dark" totalIncome={totalIncome} totalExpenses={totalExpenses+minimumLoans}/>
								</div>
							</div>

							<div className="column is-one-third">
								<div className="charts is-desktop">
									<div className="has-text-centered">
										<SpentChart
											totalIncome={totalIncome}
											totalExpenses={totalExpenses}
											minimumLoans={minimumLoans}
											extraLoans={extraLoans}
										/>
									</div>
								</div>
							</div>
						</div>

						<hr/>

						<div className="subtitle has-text-centered">
							Debt to Income Ratios
							<p className="heading has-text-centered">
								DTI is calculated using minimum payments from non-excluded loans
							</p>
							<p className="heading has-text-centered">
								43% is generally the DTI limit for a qualified mortgage
							</p>
						</div>

						<div className="columns">
							<div className="column is-two-thirds">
								<div className="level">
									<div className="level-item has-text-centered">
										<div>
											<p className="title has-text-primary-dark is-size-4-touch">
												{formatPercent(dtiGross)}
											</p>
											<p className="heading">DTI (Gross)</p>
										</div>
									</div>
									<div className="level-item has-text-centered">
										<div>
											<p className="title has-text-primary-dark is-size-4-touch">
												{formatUSD(dtiMaxLeft)}
											</p>
											<p className="heading">Gross until 43%</p>
										</div>
									</div>
									<div className="level-item has-text-centered">
										<div>
											<p className="title has-text-primary-dark is-size-4-touch">
												{formatPercent(dtiNet)}
											</p>
											<p className="heading">DTI (Net)</p>
										</div>
									</div>
								</div>
							</div>

							<div className="column is-one-third">
								<div className="charts is-desktop">
									<div className="has-text-centered">
										<DTIChart netDTI={dtiNet} grossDTI={dtiGross} />
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	income: state.income,
	expenses: state.expenses,
	loans: state.loans,
	period: state.app.period,
} );

export const ConnectedSummary = connect( mapStateToProps, mapDispatchToProps )( Summary );
