// @flow

import { Dispatch } from 'redux';
import type { Action, GetState } from '../types/actions';
import { get, post, put, del } from '../utils/rsaaHelpers';
import history from '../history';
import { stripNonNumberForSubmit } from '../utils/formattingHelpers';

export const LOANS_GET_LOANS_BEGIN = 'LOANS_GET_LOANS_BEGIN';
export const LOANS_GET_LOANS_SUCCESS = 'LOANS_GET_LOANS_SUCCESS';
export const LOANS_GET_LOANS_FAILURE = 'LOANS_GET_LOANS_FAILURE';

export const LOANS_ADD_LOAN_BEGIN = 'LOANS_ADD_LOAN_BEGIN';
export const LOANS_ADD_LOAN_SUCCESS = 'LOANS_ADD_LOAN_SUCCESS';
export const LOANS_ADD_LOAN_FAILURE = 'LOANS_ADD_LOAN_FAILURE';

export const LOANS_EDIT_SET_STATE = 'LOANS_EDIT_SET_STATE';

export const LOANS_EDIT_LOAN_BEGIN = 'LOANS_EDIT_LOAN_BEGIN';
export const LOANS_EDIT_LOAN_SUCCESS = 'LOANS_EDIT_LOAN_SUCCESS';
export const LOANS_EDIT_LOAN_FAILURE = 'LOANS_EDIT_LOAN_FAILURE';

export const LOANS_DELETE_LOAN_BEGIN = 'LOANS_DELETE_LOAN_BEGIN';
export const LOANS_DELETE_LOAN_SUCCESS = 'LOANS_DELETE_LOAN_SUCCESS';
export const LOANS_DELETE_LOAN_FAILURE = 'LOANS_DELETE_LOAN_FAILURE';

// Tracks fields as they are entered
export const LOANS_SET_ADD_FIELD = 'LOANS_SET_ADD_FIELD';

export const loansGetLoans = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( get(
		`/accounts/${activeAccount}/loans`,
		[ LOANS_GET_LOANS_BEGIN, LOANS_GET_LOANS_SUCCESS, LOANS_GET_LOANS_FAILURE ]
	) );
};

export const loansAddLoan = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().loans;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( post(
		`/accounts/${activeAccount}/loans`,
		[ LOANS_ADD_LOAN_BEGIN, LOANS_ADD_LOAN_SUCCESS, LOANS_ADD_LOAN_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
			amount: stripNonNumberForSubmit( add.amount ),
		}
	)).then( () => {
		const { errors } = getState().loans;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/loans' );
		}
	});
};

export const loansSetStateForEdit = ( id: number, state: {} ) : Action => {
	return {
		type: LOANS_EDIT_SET_STATE,
		payload: {
			id,
			state
		}
	}
};

export const loansEditLoan = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().loans;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( put(
		`/accounts/${activeAccount}/loans/${id}`,
		[ LOANS_EDIT_LOAN_BEGIN, LOANS_EDIT_LOAN_SUCCESS, LOANS_EDIT_LOAN_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
		}
	)).then( () => {
		const { errors } = getState().loans;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/loans' );
		}
	});
};

export const loansDeleteLoan = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( del(
		`/accounts/${activeAccount}/loans/${id}`,
		[ LOANS_DELETE_LOAN_BEGIN, LOANS_DELETE_LOAN_SUCCESS, LOANS_DELETE_LOAN_FAILURE ],
		{},
		{id}
	))
};

export const loansSetField = ( field: string, value: any ) => {
	return {
		type: LOANS_SET_ADD_FIELD,
		payload: {
			field,
			value
		}
	}
};
