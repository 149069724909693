import * as AccountActions from './accounts';
import * as AppActions from './app';
import * as AuthActions from './auth';
import * as BankAccountActions from './bankAccounts';
import * as BudgetActions from './budgets';
import * as ExpenseActions from './expenses';
import * as IncomeActions from './income';
import * as LoanActions from './loans';
import * as ProfileActions from './profile';
import * as RegistrationActions from './registration';
import * as UserActions from './users';

export const ActionCreators = Object.assign( {},
	AccountActions,
	AppActions,
	AuthActions,
	BankAccountActions,
	BudgetActions,
	ExpenseActions,
	IncomeActions,
	LoanActions,
	ProfileActions,
	RegistrationActions,
	UserActions,
);
