import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../utils/authUtils';

export const LoggedInRedirectRoute = ({ component: Component, loggedInRedirect, auth, ...rest }) => (
	<Route
		{...rest}
		render={props => (
			isLoggedIn( auth )
				? <Redirect
					to={{
						pathname: loggedInRedirect,
						state: { from: props.location }
					}}
				/>
				: <Component {...props} />
		)}
	/>
);
