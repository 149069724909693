// @flow

import type { authState } from '../types/state';

export const accessTokenLocalStorageKey = 'budgetAccessToken';
export const accessTokenExpirationLocalStorageKey = 'budgetAccessTokenExpiration';

export const setLocalStorageAuth = ( token: string, expiration: number ) => {
	if ( window && window.localStorage ) {
		window.localStorage.setItem( accessTokenLocalStorageKey, token );
		window.localStorage.setItem( accessTokenExpirationLocalStorageKey, expiration );
	}
};

export const getLocalStorageAuth = () : { accessToken: string, accessTokenExpiration: number } => {
	let accessToken = '';
	let accessTokenExpiration = 0;

	if ( window && window.localStorage ) {
		accessToken = window.localStorage.getItem( accessTokenLocalStorageKey );
		accessTokenExpiration = window.localStorage.getItem( accessTokenExpirationLocalStorageKey );
	}

	return { accessToken, accessTokenExpiration };
};

export const clearLocalStorageAuth = () => {
	if ( window && window.localStorage ) {
		window.localStorage.removeItem( accessTokenLocalStorageKey );
		window.localStorage.removeItem( accessTokenExpirationLocalStorageKey );
	}
};

export const isLoggedIn = function( auth: authState ) : boolean {
	if ( '' === auth.accessToken ) {
		return false;
	} else if ( auth.accessTokenExpiration < Date.now() ) {
		// @todo dispatch action to trigger token clearing?
		return false;
	}

	return true;
};
