// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { AccountsAddState, State } from '../../types/state';
import { accountsAddAccount, accountsSetField } from '../../actions/accounts';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	accountsSetField: typeof accountsSetField;
	accountsAddAccount: typeof accountsAddAccount;
	addState: AccountsAddState;
	adding: boolean;
	errors: string[];
};

class AddAccount extends Component<Props> {

	render() {
		let disabled = false;
		if ( this.props.adding ) {
			disabled = true;
		}

		const { errors } = this.props;

		return (
			<ConnectedSectionWrapper
				title="Add Account"
				render={() => (
					<>
						<form className="account-form section" onSubmit={ event => { event.preventDefault(); this.props.accountsAddAccount(); }}>
							<div className="columns">
								<div className="field column">
									<label htmlFor="name" className="label">Name</label>
									<div className="control">
										<input type="text" id="name" className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder="Name" onChange={event => {this.props.accountsSetField( 'name', event.target.value )}} value={this.props.addState.name} />
									</div>
									<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
								</div>
							</div>

							<div className={`field is-grouped`}>
								<div className={`control`}>
									<button disabled={disabled} className="button is-primary" onClick={this.props.accountsAddAccount}>
										Add
									</button>
								</div>
							</div>
						</form>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	errors: state.accounts.errors,
	addState: state.accounts.add,
	adding: state.accounts.adding,
});

export const ConnectedAddAccount = connect( mapStateToProps, mapDispatchToProps )( AddAccount );
