// @flow

import type { AccountingObjectBase } from '../types/objects';
import dotProp from 'dot-prop-immutable';

const WEEKLY = 'weekly';
const EVERY_TWO_WEEKS = 'every-two-weeks';
const TWICE_MONTHLY = 'twice-monthly';
export const MONTHLY = 'monthly';
const QUARTERLY = 'quarterly';
const SEMI_ANNUALLY = 'semi-annually';
export const ANNUALLY = 'annually';

export const ORDERED_FREQUENCIES = [
	WEEKLY,
	EVERY_TWO_WEEKS,
	TWICE_MONTHLY,
	MONTHLY,
	QUARTERLY,
	SEMI_ANNUALLY,
	ANNUALLY,
];

const annualMultipliers = {
	[WEEKLY]: 52,
	[EVERY_TWO_WEEKS]: 26,
	[TWICE_MONTHLY]: 24,
	[MONTHLY]: 12,
	[QUARTERLY]: 4,
	[SEMI_ANNUALLY]: 2,
	[ANNUALLY]: 1,
};

const monthlyMultipliers = {
	[WEEKLY]: annualMultipliers[ WEEKLY ] / 12,
	[EVERY_TWO_WEEKS]: annualMultipliers[ EVERY_TWO_WEEKS ] / 12,
	[TWICE_MONTHLY]: annualMultipliers[ TWICE_MONTHLY ] / 12,
	[MONTHLY]: annualMultipliers[ MONTHLY ] / 12,
	[QUARTERLY]: annualMultipliers[ QUARTERLY ] / 12,
	[SEMI_ANNUALLY]: annualMultipliers[ SEMI_ANNUALLY ] / 12,
	[ANNUALLY]: annualMultipliers[ ANNUALLY ] / 12,
};

export const FREQUENCY_LABELS = {
	[WEEKLY] : {
		listing: "Weekly",
		recurring: "Weekly",
	},
	[EVERY_TWO_WEEKS] : {
		listing: "Every Two Week",
		recurring: "Every Two Weeks",
	},
	[TWICE_MONTHLY] : {
		listing: "Twice Monthly",
		recurring: "Twice Monthly",
	},
	[MONTHLY] : {
		listing: "Monthly",
		recurring: "Monthly",
	},
	[QUARTERLY] : {
		listing: "Quarterly",
		recurring: "Quarterly",
	},
	[SEMI_ANNUALLY] : {
		listing: "Semi-Annual",
		recurring: "Semi-Annually",
	},
	[ANNUALLY] : {
		listing: "Annual",
		recurring: "Annually",
	},
};

export const getPeriodLabel = function( period: string, label: ?string ) : string {
	label = label ? label : 'listing';

	return dotProp.get( FREQUENCY_LABELS, `${period}.${label}`, '' );
};

export const getPeriodEquivalent = function( object: AccountingObjectBase, period: string ) : number {
	switch ( period ) {
		case MONTHLY:
			return getMonthlyEquivalent( object );
		case ANNUALLY:
			return getAnnualEquivalent( object );
		default:
			return object.amount;
	}
};

export const getPeriodSum = function( collection: AccountingObjectBase[], period: string ) : number {
	switch ( period ) {
		case MONTHLY:
			return getMonthlySum( collection );
		case ANNUALLY:
			return getAnnualSum( collection );
		default:
			return collection.reduce( ( total, current ) => ( total + parseFloat( current.amount ) ), 0 );
	}
};

const getMonthlyEquivalent = function( object: AccountingObjectBase ) : number {
	let multiplier = monthlyMultipliers[ object.frequency ];

	return object.amount * multiplier;
};

const getMonthlySum = function( collection: AccountingObjectBase[] ) : number {
	return collection.reduce( ( total, current ) => ( total + getMonthlyEquivalent( current ) ), 0.0000 );
};

const getAnnualEquivalent = function( object: AccountingObjectBase ) : number {
	let multiplier = annualMultipliers[ object.frequency ];

	return object.amount * multiplier;
};

const getAnnualSum = function( collection: AccountingObjectBase[] ) : number {
	return collection.reduce( ( total, current ) => ( total + getAnnualEquivalent( current ) ), 0.0000 );
};
