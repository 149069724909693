// @flow

import { Dispatch } from 'redux';
import type { GetState } from '../types/actions';
import { get } from '../utils/rsaaHelpers';
import type { Budget } from '../types/objects';
import { isLoggedIn } from '../utils/authUtils';

export const BUDGETS_GET_BUDGETS_BEGIN = 'BUDGETS_GET_BUDGETS_BEGIN';
export const BUDGETS_GET_BUDGETS_SUCCESS = 'BUDGETS_GET_BUDGETS_SUCCESS';
export const BUDGETS_GET_BUDGETS_FAILURE = 'BUDGETS_GET_BUDGETS_FAILURE';

export const BUDGETS_SET_ACTIVE_BUDGET = 'BUDGETS_SET_ACTIVE_BUDGET';

export const budgetsGetBudgets = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { auth, accounts } = getState();

	if ( isLoggedIn( auth ) && accounts.activeAccount ) {
		dispatch( get(
			`/accounts/${accounts.activeAccount}/budgets`,
			[ BUDGETS_GET_BUDGETS_BEGIN, BUDGETS_GET_BUDGETS_SUCCESS, BUDGETS_GET_BUDGETS_FAILURE ]
		) ).then(() => {
			const { budgets } = getState();

			if ( budgets.ids.length === 1 ) {
				dispatch( budgetsSetActiveBudget( budgets.objects[ budgets.ids[0] ] ) );
			}
		});
	}
};

export const budgetsSetActiveBudget = ( budget: Budget ) => {
	return {
		type: BUDGETS_SET_ACTIVE_BUDGET,
		payload: {
			id: budget.id
		}
	}
};
