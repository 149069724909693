// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {AccountsState, State} from '../../types/state';
import { returnSubsetById } from '../../utils/stateHelpers';
import { accountsSetActiveAccount } from '../../actions/accounts';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { Link } from 'react-router-dom';

type Props = {
	accounts: AccountsState;
	accountsSetActiveAccount: typeof accountsSetActiveAccount;
};

export class AccountSwitcher extends Component<Props> {

	render() {
		let accounts = returnSubsetById( this.props.accounts.ids, this.props.accounts.objects );

		return (
			<ConnectedSectionWrapper
				title={"Switch Accounts"}
				render={() => (
					<>
						<div className="has-text-centered container">
							<Link className="button is-primary" to="/accounts/add">Add New Account</Link>
						</div>
						<div className="container section">
							{accounts.map( account => (
								<a className="box is-fullwidth" key={account.id} onClick={() => { this.props.accountsSetActiveAccount( account.id ) }}>
									<span className="is-size-5">{account.name}</span>
									<p className="heading">{account.owner_name}</p>
								</a>
							))}
						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	accounts: state.accounts,
} );

export const ConnectedAccountSwitcher = connect( mapStateToProps, mapDispatchToProps )( AccountSwitcher );

