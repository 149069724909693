// @flow

import { post } from '../utils/rsaaHelpers';
import type { Dispatch, GetState } from '../types/actions';
import type { RegistrationComponentState } from '../types/registration';
import history from '../history';

export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const register = ( registrationData : RegistrationComponentState ) => ( dispatch : Dispatch, getState : GetState ) => {
	return dispatch(post(
		`/users`,
		[ REGISTER_BEGIN, REGISTER_SUCCESS, REGISTER_FAILURE ],
		{
			name: registrationData.name,
			username: registrationData.username,
			email: registrationData.email,
			password: registrationData.password,
			password_confirmation: registrationData.password_confirmation,
		}
	)).then( () => {
		const { registration } = getState();

		if ( registration.success === true ) {
			history.push( '/login' );
		}
	});
};
