// @flow

import React, { Component } from 'react';
import { formatUSD } from '../../utils/formattingHelpers';

type Props = {
	totalLoans: number;
	color?: string;
	label?: string;
};

export class TotalLoansLevel extends Component<Props> {

	render() {
		let { totalLoans, color, label } = this.props;

		if (color == null) {
			color = "danger-dark"
		}

		if ( label == null ) {
			label = "Total Loan Payments"
		}

		return (
			<div className="level-item has-text-centered">
				<div>
					<p className={`title has-text-${color} is-size-4-touch`}>{ formatUSD( totalLoans ) }</p>
					<p className="heading">{label}</p>
				</div>
			</div>
		)
	}

}
