// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { bankAccountsAddAccount } from '../../actions/bankAccounts';
import type { BankAccountsAddState, State } from '../../types/state';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { ConnectedBankAccountForm } from './form';

type Props = {
	addState: BankAccountsAddState;
	adding: boolean;
	errors: string[];
	bankAccountsAddAccount: typeof bankAccountsAddAccount;
};

class AddBankAccount extends Component<Props> {

	render() {
		return (
			<ConnectedSectionWrapper
				title="Add Bank Account"
				render={() => (
					<>
						<ConnectedBankAccountForm
							addState={this.props.addState}
							adding={this.props.adding}
							errors={this.props.errors}
							saveCallback={this.props.bankAccountsAddAccount}
						/>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.bankAccounts.add,
	adding: state.bankAccounts.adding,
	errors: state.bankAccounts.errors,
});

export const ConnectedAddBankAccount = connect( mapStateToProps, mapDispatchToProps )( AddBankAccount );
