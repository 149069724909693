// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { AccountsState, State } from "../../types/state";
import { ConnectedSectionWrapper } from "../shared/section-wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';
import {accountsCloneAccount, accountsSwitchAccount} from '../../actions/accounts';

type Props = {
	accounts: AccountsState;
	accountsSwitchAccount: typeof accountsSwitchAccount;
	accountsCloneAccount: typeof accountsCloneAccount;
}

type ComponentState = {
	newName: string;
};

class CloneAccount extends Component<Props, ComponentState> {

	state = {
		newName: ''
	};

	clone = () => {
		this.props.accountsCloneAccount( this.state.newName ).then(() => {
			if ( Object.keys( this.props.accounts.errors ).length === 0 ) {
				this.props.accountsSwitchAccount()
			}
		});
	};

	render() {
		let account = this.props.accounts.objects[ this.props.accounts.activeAccount ];
		let isLoading = this.props.accounts.cloning === true ? 'is-loading' : '';
		const { errors } = this.props.accounts;

		return (
			<ConnectedSectionWrapper
				title="Clone Account"
				subtitle={account && account.name ? account.name : '' }
				render={() => (
					<>
						<form className="section" onSubmit={event => {
							event.preventDefault();
							this.clone();
						}}>
							<div className={`field`}>
								<label htmlFor="name" className="label">New Account Name</label>
								<div className="control has-icons-left">
									<input id={`name`} className={`input ${formFieldErrorClass(errors, 'name')} ${formFieldErrorClass(errors, 'generic')}`} placeholder={`Account Name`} type="text"
										   value={this.state.newName}
										   onChange={event => {
											   this.setState( { newName: event.target.value } )
										   }}
									/>
									<span className={`icon is-small is-left`}>
										<FontAwesomeIcon icon="plus"/>
									</span>
								</div>
								<p className="help is-danger">{formErrorsForField(errors, 'account-name')}</p>
								<p className="help is-danger">{formErrorsForField(errors, 'generic')}</p>
							</div>

							<div className="field">
								<button className={`button is-info ${isLoading}`} onClick={this.clone}>Clone</button>
							</div>
						</form>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	accounts: state.accounts,
});

export const ConnectedCloneAccount = connect( mapStateToProps, mapDispatchToProps )( CloneAccount );
