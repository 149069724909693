// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { ExpensesAddState, State } from '../../types/state';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';

import {
	expensesAddSplit,
	expensesAddExpense,
	expensesSetField,
	expensesSetStateForEdit
} from '../../actions/expenses';
import { ConnectedExpenseForm } from './form';


type Props = {
	expensesAddExpense: typeof expensesAddExpense;
	expensesSetStateForEdit: typeof expensesSetStateForEdit;
	addState: ExpensesAddState;
	expensesSetField: typeof expensesSetField;
	expensesAddSplit: typeof expensesAddSplit;
	adding: boolean;
	errors: string[];
	editing: number;
};

class AddExpense extends Component<Props> {

	componentWillMount() {
		// Reset the state if we were previously editing an expense and haven't cleared yet
		if ( this.props.editing !== 0 ) {
			this.props.expensesSetStateForEdit( 0, {} );
		}
	}

	render() {
		return (
			<ConnectedSectionWrapper
				title="Add Expense"
				render={() => (
					<ConnectedExpenseForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.props.expensesAddExpense}
					/>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.expenses.add,
	adding: state.expenses.adding,
	errors: state.expenses.errors,
	editing: state.expenses.editing,
});

export const ConnectedAddExpense = connect( mapStateToProps, mapDispatchToProps )( AddExpense );
