// @flow

import React, { Component } from 'react';

import { Doughnut } from 'react-chartjs-2';

type Props = {
	totalIncome: number;
	totalExpenses: number;
	minimumLoans: number;
	extraLoans: number;
};

export class SpentChart extends Component<Props> {

	render() {
		const { totalIncome, totalExpenses, minimumLoans, extraLoans } = this.props;

		const options = {
			tooltips: {
				enabled: false,
			},
		};

		const dataset = {
			data: [totalExpenses, minimumLoans, extraLoans],
			backgroundColor: ["#cc0f35", "#947600", "#1d72aa"]
		};

		if ( totalExpenses < totalIncome ) {
			let left = totalIncome - totalExpenses - minimumLoans - extraLoans;
			if (left < 0 ) {
				left = 0
			}
			dataset.data.push(left)
			dataset.backgroundColor.push("#00947e")
		}

		const data = {
			datasets: [dataset],
		};

		return (
			<Doughnut data={data} options={options} style={{width: 250, height: 250}}/>
		)
	}

}
