// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { Node } from 'react';
import type { State } from '../../types/state';
import { appSetPeriod } from '../../actions/app';
import { MONTHLY, ANNUALLY, getPeriodLabel } from '../../utils/frequencyHelpers';

type Props = {
	render: () => Node;
	title: string;
	subtitle: ?string;
	appSetPeriod: typeof appSetPeriod;
	period: string;
	periodToggle: ?boolean;
};

export class SectionWrapper extends Component<Props> {

	periodToggleVisible = () : boolean => {
		let { periodToggle } = this.props;

		return typeof periodToggle === 'boolean' ? periodToggle : false;
	};

	render() {
		const monthlyClass = this.props.period === MONTHLY ? 'is-primary is-active' : '',
			annualClass = this.props.period === ANNUALLY ? 'is-primary is-active' : '';

		return (
			<div className="section-wrapper">
				<div className="title-section section">
					{this.periodToggleVisible() === true && (
						<div className="time-period-buttons buttons has-addons">
							<button
								className={`button ${monthlyClass}`}
								onClick={() => { this.props.appSetPeriod( MONTHLY )}}
							>
								{getPeriodLabel( MONTHLY )}
							</button>
							<button
								className={`button ${annualClass}`}
								onClick={() => { this.props.appSetPeriod( ANNUALLY )}}
							>
								{getPeriodLabel( ANNUALLY )}
							</button>
						</div>
					)}
					<div className="title-wrap">
						<h1 className="title has-text-centered">{this.props.title}</h1>
						{this.props.subtitle && this.props.subtitle.length > 0 && (
							<h2 className="subtitle has-text-centered">{this.props.subtitle}</h2>
						)}
					</div>
				</div>


				<div className="section">
					{this.props.render()}
				</div>
			</div>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	period: state.app.period,
});

export const ConnectedSectionWrapper = connect( mapStateToProps, mapDispatchToProps )( SectionWrapper );
