// @flow

export const activeBudgetLocalStorageKey = 'budgetActiveBudget';

export const setLocalStorageActiveBudget = ( budgetID: number ) => {
	if ( window && window.localStorage ) {
		window.localStorage.setItem( activeBudgetLocalStorageKey, budgetID );
	}
};

export const getLocalStorageActiveBudget = () : number => {
	let activeBudget = 0;

	if ( window && window.localStorage ) {
		activeBudget = window.localStorage.getItem( activeBudgetLocalStorageKey );
	}

	return activeBudget;
};

export const clearLocalStorageActiveBudget = () => {
	if ( window && window.localStorage ) {
		window.localStorage.removeItem( activeBudgetLocalStorageKey );
	}
};
