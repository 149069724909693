// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from './utils/dispatchToProps';

import 'bulma/css/bulma.css';
import './App.css';

import { Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/routes/privateRoute';
import history from './history';

import { ConnectedHome } from './components/home';
import { ConnectedSiteHeader } from './components/siteheader/SiteHeader';
import { ConnectedSiteFooter } from './components/sitefooter/SiteFooter';
import { NotFound } from './components/notfound';
import { ConnectedLogin } from './components/login';
import { ConnectedSummary } from './components/summary';
import { ConnectedIncome } from './components/income';
import { ConnectedExpenses } from './components/expenses';
import { ConnectedBankAccounts } from './components/bankAccounts';
import { ConnectedLoans } from './components/loans';
import type { AccountsState, authState, State } from './types/state';
import { LoggedInRedirectRoute } from './components/routes/LoggedInRedirectRoute';
import { ConnectedAddExpense } from './components/expenses/add';
import { ConnectedEditExpense } from './components/expenses/edit';
import { ConnectedAddIncome } from './components/income/add';
import { accountsGetAccounts } from './actions/accounts';
import { budgetsGetBudgets } from './actions/budgets';
import { ConnectedAddBankAccount } from './components/bankAccounts/add';
import { ConnectedAddLoan } from './components/loans/add';
import { ConnectedRegister } from './components/register';

import { LoggedOutRedirectRoute } from './components/routes/LoggedOutRedirectRoute';
import { ConnectedAddAccount } from './components/accounts/add';
import { ConnectedEditIncome } from './components/income/edit';
import { ConnectedEditLoan } from './components/loans/edit';
import {appCloseMenu} from "./actions/app";
import {profileGetProfile} from "./actions/profile";
import {ConnectedProfile} from "./components/profile";
import {ConnectedManageAccount} from "./components/accounts/manage";
import {ConnectedCloneAccount} from "./components/accounts/clone"
import {ConnectedInviteUser} from "./components/users/invite";
import { ConnectedProfileInvites } from './components/profile/invites';

// Font Awesome Setup
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faLock, faPlus, faEnvelope, faEdit, faTrash, faCheck, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { ConnectedEditBankAccount } from './components/bankAccounts/edit';
library.add( faUser, faLock, faPlus, faEnvelope, faEdit, faTrash, faCheck, faStopwatch );


type Props = {
	auth: authState;
	accounts: AccountsState;
	accountsGetAccounts: typeof accountsGetAccounts;
	budgetsGetBudgets: typeof budgetsGetBudgets;
	appCloseMenu: typeof appCloseMenu;
	profileGetProfile: typeof profileGetProfile;
};

export class App extends Component<Props> {

	componentWillMount() {
		history.listen( ( location, action ) => {
			window && window.scrollTo && window.scrollTo( 0, 0 );
			this.props.appCloseMenu();
		});

		this.initialLoad();
	}

	componentWillUpdate() {
		this.initialLoad();
	}

	initialLoad = () => {
		this.props.accountsGetAccounts();
		this.props.budgetsGetBudgets();
		this.props.profileGetProfile();
	};

	routes = () => {
		const { auth } = this.props;

		return (
			<>
				<Switch>
					<PrivateRoute
						exact
						path={'/summary'}
						component={ConnectedSummary}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/income'}
						component={ConnectedIncome}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/income/add'}
						component={ConnectedAddIncome}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/income/edit/:id'}
						component={ConnectedEditIncome}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/expenses'}
						component={ConnectedExpenses}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/expenses/add'}
						component={ConnectedAddExpense}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/expenses/edit/:id'}
						component={ConnectedEditExpense}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/bank_accounts'}
						component={ConnectedBankAccounts}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/bank_accounts/edit/:id'}
						component={ConnectedEditBankAccount}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/bank_accounts/add'}
						component={ConnectedAddBankAccount}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/loans'}
						component={ConnectedLoans}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/loans/add'}
						component={ConnectedAddLoan}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/loans/edit/:id'}
						component={ConnectedEditLoan}
						accounts={this.props.accounts}
						auth={auth}
					/>

					<LoggedOutRedirectRoute
						exact
						path={'/accounts/add'}
						component={ConnectedAddAccount}
						loggedOutRedirect={"/login"}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/accounts/manage'}
						component={ConnectedManageAccount}
						accounts={this.props.accounts}
						loggedOutRedirect={"/login"}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/accounts/clone'}
						component={ConnectedCloneAccount}
						accounts={this.props.accounts}
						loggedOutRedirect={"/login"}
						auth={auth}
					/>

					<PrivateRoute
						exact
						path={'/users/invite'}
						component={ConnectedInviteUser}
						accounts={this.props.accounts}
						loggedOutRedirect={"/login"}
						auth={auth}
					/>

					<LoggedInRedirectRoute
						exact
						path={'/login'}
						component={ConnectedLogin}
						loggedInRedirect="/summary"
						auth={auth}
					/>

					<LoggedInRedirectRoute
						exact
						path={'/register'}
						component={ConnectedRegister}
						loggedInRedirect="/summary"
						auth={auth}
					/>

					<LoggedOutRedirectRoute
						exact
						path="/profile/invites"
						component={ConnectedProfileInvites}
						loggedOutRedirect="/login"
						auth={auth}
					/>

					<LoggedOutRedirectRoute
						exact
						path="/profile"
						component={ConnectedProfile}
						loggedOutRedirect="/login"
						auth={auth}
					/>

					<LoggedInRedirectRoute
						exact
						path={'/'}
						component={ConnectedHome}
						loggedInRedirect="/summary"
						auth={auth}
					/>

					<Route
						path={'/'}
						component={NotFound}
					/>
				</Switch>
			</>
		);
	};

	render() {
		return (
			<Router history={history}>
				<div className={'site'}>
					<ConnectedSiteHeader />

					<main
						id="budget-main"
						className={`main-content`}
					>
						{this.routes()}
					</main>

					<ConnectedSiteFooter />
				</div>
			</Router>
		);
	}
}

const mapStateToProps = ( state: State ) => ({
	auth: state.auth,
	accounts: state.accounts,
	profile: state.profile,
});

export const ConnectedApp = connect( mapStateToProps, mapDispatchToProps )( App );
