import createReducer from '../utils/createReducer';
import {
	BANK_ACCOUNTS_ADD_ACCOUNT_BEGIN,
	BANK_ACCOUNTS_ADD_ACCOUNT_FAILURE,
	BANK_ACCOUNTS_ADD_ACCOUNT_SUCCESS, BANK_ACCOUNTS_DELETE_ACCOUNT_SUCCESS, BANK_ACCOUNTS_EDIT_SET_STATE,
	BANK_ACCOUNTS_GET_ACCOUNTS_SUCCESS,
	BANK_ACCOUNTS_SET_ADD_FIELD,
} from '../actions/bankAccounts';
import {
	addEditBeginState, addEditFailedState, addEditSuccessState,
	deleteSingleObjectFromState,
	objectDataToState, setEditStateHelper,
} from '../utils/stateHelpers';
import { defaultObjectState } from './common/objectState';
import dotProp from 'dot-prop-immutable';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';

let defaultBankAccountState = {
	...defaultObjectState,
	add: {
		name: '',
	}
};

export const bankAccounts = createReducer( defaultBankAccountState, {
	[BANK_ACCOUNTS_GET_ACCOUNTS_SUCCESS]( state, action ) {
		state = objectDataToState( state, action );

		return state;
	},
	[BANK_ACCOUNTS_SET_ADD_FIELD]( state, action ) {
		return dotProp.set( state, `add.${action.payload.field}`, action.payload.value );
	},
	[BANK_ACCOUNTS_ADD_ACCOUNT_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[BANK_ACCOUNTS_ADD_ACCOUNT_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultBankAccountState );
	},
	[BANK_ACCOUNTS_ADD_ACCOUNT_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[BANK_ACCOUNTS_EDIT_SET_STATE]( state, action ) {
		return setEditStateHelper( state, action, defaultBankAccountState );
	},
	[BANK_ACCOUNTS_DELETE_ACCOUNT_SUCCESS]( state, action ) {
		return deleteSingleObjectFromState( state, action.meta.id );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultBankAccountState;
	}
});
