// @flow

import React, { Component } from 'react';
import { formatUSD } from '../../utils/formattingHelpers';

type Props = {
	totalIncome: number;
	totalExpenses: number;
	label?: string;
	color?: string;
};

export class IncomeRemainingLevel extends Component<Props> {

	render() {
		const { totalIncome, totalExpenses } = this.props;
		let { label, color } = this.props;
		let remainingIncome = totalIncome - totalExpenses;

		if (color == null) {
			color = 'primary-dark';
		}
		// Use the color specified unless negative
		let finalColor = remainingIncome >= 0 ? color : 'danger-dark';

		if (label == null) {
			label = "Income Remaining";
		}

		return (
			<div className="level-item has-text-centered">
				<div>
					<p className={`title has-text-${finalColor} is-size-4-touch`}>{ formatUSD( remainingIncome ) }</p>
					<p className="heading">{label}</p>
				</div>
			</div>
		)
	}

}
