// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { expensesDeleteExpense, expensesGetExpenses } from '../../actions/expenses';
import type { BankAccountsState, ExpensesState, State } from '../../types/state';
import { returnSubsetById } from '../../utils/stateHelpers';
import {formatUSD, nl2br} from '../../utils/formattingHelpers';
import { FREQUENCY_LABELS, getPeriodEquivalent, getPeriodLabel, getPeriodSum } from '../../utils/frequencyHelpers';
import { groupExpensesByFrequency, orderExpenseGroupsByDate, organizeExpenses } from '../../utils/objectHelpers';
import { TotalExpensesLevel } from './totalExpensesLevel';
import { bankAccountsGetAccounts } from '../../actions/bankAccounts';
import type { LoanSingleObject } from '../../types/objects';
import { loansGetLoans } from '../../actions/loans';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteConfirm } from '../shared/buttons/deleteConfirm';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';

type Props = {
	expensesGetExpenses: typeof expensesGetExpenses;
	expensesDeleteExpense: typeof expensesDeleteExpense;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
	loansGetLoans: typeof loansGetLoans;
	expenses: ExpensesState;
	bankAccounts: BankAccountsState;
	loans: LoanSingleObject[];
	period: string;
};

type ComponentState = {
	expandedExpense: number;
};

class Expenses extends Component<Props, ComponentState> {

	state = {
		expandedExpense: 0,
	};

	componentWillMount() {
		this.props.expensesGetExpenses();
		this.props.bankAccountsGetAccounts();
	}

	getBankAccountName = ( accountID: number ) : string => {
		const { objects } = this.props.bankAccounts;

		if ( objects[ accountID ] ) {
			return objects[ accountID ].name;
		}

		return '';
	};

	handleToggle = ( id: number ) => {
		if ( this.state.expandedExpense === id ) {
			this.setState({ expandedExpense: 0 });
		} else {
			this.setState({ expandedExpense: id });
		}
	};

	render() {
		let organizedExpenses = organizeExpenses( returnSubsetById( this.props.expenses.ids, this.props.expenses.objects ) ),
			groupedExpenses = groupExpensesByFrequency( organizedExpenses );

		groupedExpenses = orderExpenseGroupsByDate( groupedExpenses );

		return (
			<ConnectedSectionWrapper
				periodToggle={true}
				title="Expense Details"
				render={() => (
					<>
						<div className="level">
							<TotalExpensesLevel totalExpenses={getPeriodSum( organizedExpenses, this.props.period )}/>
						</div>

						<div className="add-expense has-text-right">
							<Link className="button is-primary" to="/expenses/add">
						<span className="icon">
							<FontAwesomeIcon icon="plus"/>
						</span>
								<span>Add Expense</span>
							</Link>
						</div>

						<div className="expenses-list">
							{ groupedExpenses.map( group => {
								const { frequency, collection } = group;

								return (
									<div className="collectiongroup section has-stacked-panels" key={frequency}>
										<h2 className="title is-5">{FREQUENCY_LABELS[ frequency ].listing} Expenses</h2>

										<div className="expense columns legend is-mobile">
											<strong className="column is-1 is-hidden-mobile">Date</strong>
											<strong className="column is-3">Name</strong>
											<strong className="column is-hidden-mobile">Bank Account</strong>
											<strong className="column is-hidden-mobile">Autopay</strong>
											<strong className="column has-text-right">{getPeriodLabel( this.props.period )} Total</strong>
										</div>

										{collection.map( expense => {
											let isHidden = this.state.expandedExpense === expense.id ? '' : 'is-hidden',
												isActive = this.state.expandedExpense === expense.id ? 'is-active' : '';

											return (
												<div className="panel" key={expense.id}>
													<div className="panel-heading has-interaction is-size-6 has-text-weight-normal" onClick={() => { this.handleToggle( expense.id )}}>
														<div className='columns is-mobile' key={expense.id}>
															<span className="column is-1 is-hidden-mobile">{ expense.date || '–' }</span>
															<span className="column is-3">{ expense.name }</span>
															<span className="column is-hidden-mobile">{ this.getBankAccountName( expense.bank_account_id ) }</span>
															<span className="column is-hidden-mobile">{ expense.autopay ? 'Y' : '' }</span>
															<span className="column has-text-right">{ formatUSD( getPeriodEquivalent( expense, this.props.period ) ) }</span>
														</div>
													</div>

													<div className={`panel-block is-hidden-tablet ${isHidden} ${isActive}`}>
														<div className="field is-fullwidth">
															<span className="label is-inline">Date:&nbsp;</span>
															<span>{ expense.date || '–' }</span>
														</div>
													</div>
													<div className={`panel-block is-hidden-tablet ${isHidden} ${isActive}`}>
														<div className="field is-fullwidth">
															<span className="label is-inline">Bank Account:&nbsp;</span>
															<span>{ this.getBankAccountName( expense.bank_account_id ) }</span>
														</div>
													</div>
													<div className={`panel-block is-hidden-tablet ${isHidden} ${isActive}`}>
														<div className="field is-fullwidth">
															<span className="label is-inline">Autopay:&nbsp;</span>
															<span>{ expense.autopay ? 'Y' : '' }</span>
														</div>
													</div>

													{expense.children.length > 0 && (
														<div className={`panel-block ${isHidden} ${isActive}`}>
															<div className="split-container">
																<h4 className={'subtitle'}>Splits</h4>

																<ul>
																	{expense.children.map( child => {
																		return (
																			<li className="columns" key={child.id}>
																		<span className="column">
																			{child.name}
																		</span>
																				<span className="column">
																			{formatUSD(getPeriodEquivalent( child, this.props.period ))}
																		</span>
																			</li>
																		)
																	})}
																</ul>
															</div>
														</div>
													)}

													{expense.notes.length > 0 && (
														<div className={`panel-block ${isHidden} ${isActive}`}>
															<p className="content">
																{nl2br(expense.notes)}
															</p>
														</div>
													)}

													<div className={`panel-block ${isHidden} ${isActive}`}>
														<div className="columns">
															<div className="column">
																<Link className="button is-small" to={`/expenses/edit/${expense.id}`}>
																	<span className="icon">
																		<FontAwesomeIcon icon={'edit'}/>
																	</span>
																	<span>Edit Expense</span>
																</Link>
															</div>
															<div className="column">
																<DeleteConfirm className={"is-centered"} buttonText="Delete Expense" deleteMethod={() => { this.props.expensesDeleteExpense( expense.id ) }} />
															</div>
														</div>
													</div>
												</div>
											)
										})}

										<p className="has-text-right">
											<strong>{formatUSD( getPeriodSum( collection, this.props.period ) )}</strong>
										</p>
									</div>
								)

							})}
						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	expenses: state.expenses,
	bankAccounts: state.bankAccounts,
	period: state.app.period,
} );

export const ConnectedExpenses = connect( mapStateToProps, mapDispatchToProps )( Expenses );
