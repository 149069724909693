// @flow
import React from 'react';
import type { ErrorsState } from '../types/state';
import dotProp from 'dot-prop-immutable';

export const formFieldHasErrors = ( errors: ErrorsState, field: string ) : boolean => {
	let fieldErrors = dotProp.get( errors, field, false );
	return fieldErrors !== false;
};

export const formFieldErrorClass = ( errors: ErrorsState, field: string ) : string => {
	return formFieldHasErrors( errors, field ) ? 'is-danger' : '';
};

export const formErrorsForField = ( errors: ErrorsState, field: string ) : string => {
	if ( ! formFieldHasErrors( errors, field ) ) {
		return '';
	}

	let fieldErrors = dotProp.get( errors, field, [] );
	return fieldErrors.map( (error, index) => {
		return (
			<li key={`${field}-${index}`}>{error}</li>
		)
	})
};
