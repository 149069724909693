// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { incomeEditIncome, incomeGetIncome, incomeSetStateForEdit } from '../../actions/income';
import type { IncomeAddState, IncomeState, State } from '../../types/state';
import type { Match } from 'react-router-dom';
import { returnSubsetByIdOrParent } from '../../utils/stateHelpers';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { ConnectedIncomeForm } from './form';

type Props = {
	incomeGetIncome: typeof incomeGetIncome;
	incomeSetStateForEdit: typeof incomeSetStateForEdit;
	incomeEditIncome: typeof incomeEditIncome;
	addState: IncomeAddState;
	adding: boolean;
	errors: string[];
	match: Match;
	income: IncomeState;
};

class EditIncome extends Component<Props> {

	componentWillMount() {
		this.initialLoad();
	}

	componentDidUpdate() {
		this.initialLoad();
	}

	initialLoad = () => {
		this.props.incomeGetIncome();

		this.setEditState();
	};

	setEditState = () => {
		if ( this.props.income.ids.indexOf( this.getId() ) === -1 ) {
			return false;
		}

		if ( this.props.income.editing === this.getId() ) {
			return false;
		}

		let incomeState = returnSubsetByIdOrParent( this.getId(), this.props.income.objects );
		incomeState = incomeState[0];

		this.props.incomeSetStateForEdit( this.getId(), incomeState );
	};

	getId = () : number => {
		let { id } = this.props.match.params;

		id = 'string' === typeof id ? id : '';

		// get rid of bad characters
		id = id.replace( '\ufeff', '' );

		return parseInt( id, 10 );
	};

	save = () => {
		this.props.incomeEditIncome( this.getId() );
	};

	render() {
		return (
			<ConnectedSectionWrapper
				title="Edit Income"
				render={() => (
					<ConnectedIncomeForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.save}
					/>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.income.add,
	adding: state.income.adding,
	errors: state.income.errors,
	income: state.income,
});

export const ConnectedEditIncome = connect( mapStateToProps, mapDispatchToProps )( EditIncome );
