import { combineReducers } from 'redux';
import * as AccountReducers from './accounts';
import * as AppReducers from './app';
import * as AuthReducers from './auth';
import * as BankAccountReducers from './bankAccounts';
import * as BudgetReducers from './budgets';
import * as ExpenseReducers from './expenses';
import * as IncomeReducers from './income';
import * as LoanReducers from './loans';
import * as ProfileReducers from './profile';
import * as RegistrationReducers from './registration';
import * as UserReducers from './users';

export default combineReducers( Object.assign(
	AccountReducers,
	AppReducers,
	AuthReducers,
	BankAccountReducers,
	BudgetReducers,
	ExpenseReducers,
	IncomeReducers,
	LoanReducers,
	ProfileReducers,
	RegistrationReducers,
	UserReducers,
) );
