// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { BankAccountsState, State } from '../../types/state';
import { bankAccountsDeleteAccount, bankAccountsGetAccounts } from '../../actions/bankAccounts';
import { returnSubsetById } from '../../utils/stateHelpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteConfirm } from '../shared/buttons/deleteConfirm';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { sortObjectsByName } from '../../utils/objectHelpers';

type Props = {
	bankAccounts: BankAccountsState;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
	bankAccountsDeleteAccount: typeof bankAccountsDeleteAccount;
};

type ComponentState = {
	expandedAccount: number;
};

class BankAccounts extends Component<Props, ComponentState> {

	state = {
		expandedAccount: 0,
	};

	componentWillMount() {
		this.props.bankAccountsGetAccounts();
	}

	handleToggle = ( id: number ) => {
		if ( this.state.expandedAccount === id ) {
			this.setState({ expandedAccount: 0 });
		} else {
			this.setState({ expandedAccount: id });
		}
	};

	render() {
		let accounts = sortObjectsByName( returnSubsetById( this.props.bankAccounts.ids, this.props.bankAccounts.objects ) );

		return (
			<ConnectedSectionWrapper
				title={"Bank Accounts"}
				render={() => (
					<>
						<div className="add-bankaccount has-text-right">
							<Link className="button is-primary" to="/bank_accounts/add">
						<span className="icon">
							<FontAwesomeIcon icon="plus"/>
						</span>
								<span>Add Bank Account</span>
							</Link>
						</div>

						<div className="bank-accounts-list section has-stacked-panels">
							{ accounts.map( account => {
								let isHidden = this.state.expandedAccount === account.id ? '' : 'is-hidden',
									isActive = this.state.expandedAccount === account.id ? 'is-active' : '';

								return (
									<div className={`panel ${isActive}`} key={account.id}>
										<div className='panel-heading has-interaction is-size-6 has-text-weight-normal' onClick={() => { this.handleToggle( account.id )}}>
											<span className="column">{ account.name }</span>
										</div>

										<div className={`panel-block ${isHidden} ${isActive}`}>
											<div className="columns">
												<div className="column">
													<Link className="button is-small" to={`/bank_accounts/edit/${account.id}`}>
														<span className="icon">
															<FontAwesomeIcon icon={'edit'}/>
														</span>
														<span>Edit Bank Account</span>
													</Link>
												</div>
												<div className="column">
													<DeleteConfirm className={"is-centered"} buttonText="Delete Bank Account" deleteMethod={() => { this.props.bankAccountsDeleteAccount( account.id ) }} />
												</div>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state : State ) => ( {
	bankAccounts: state.bankAccounts,
} );

export const ConnectedBankAccounts = connect( mapStateToProps, mapDispatchToProps )( BankAccounts );
