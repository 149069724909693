import createReducer from '../utils/createReducer';
import {
	LOANS_ADD_LOAN_BEGIN,
	LOANS_ADD_LOAN_FAILURE,
	LOANS_ADD_LOAN_SUCCESS,
	LOANS_DELETE_LOAN_SUCCESS,
	LOANS_EDIT_LOAN_BEGIN,
	LOANS_EDIT_LOAN_FAILURE,
	LOANS_EDIT_LOAN_SUCCESS,
	LOANS_EDIT_SET_STATE,
	LOANS_GET_LOANS_SUCCESS,
	LOANS_SET_ADD_FIELD,
} from '../actions/loans';
import {
	addEditBeginState,
	addEditFailedState, addEditSuccessState,
	deleteSingleObjectFromState,
	objectDataToState, setEditStateHelper
} from '../utils/stateHelpers';
import { defaultObjectState } from './common/objectState';
import dotProp from 'dot-prop-immutable';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';

let defaultLoansState = {
	...defaultObjectState,
	add: {
		name: '',
		amount: 0.0000,
		frequency: '',
		date: '',
		autopay: false,
		bank_account_id: 0,
		notes: '',
		interest_rate: 0.000000,
		minimum_payment: 0.00,
		extra_payment: 0.00,
		dti_include: true,
	}
};

export const loans = createReducer( defaultLoansState, {
	[LOANS_GET_LOANS_SUCCESS]( state, action ) {
		state = objectDataToState( state, action );

		return state;
	},
	[LOANS_SET_ADD_FIELD]( state, action ) {
		return dotProp.set( state, `add.${action.payload.field}`, action.payload.value );
	},
	[LOANS_ADD_LOAN_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[LOANS_ADD_LOAN_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultLoansState );
	},
	[LOANS_ADD_LOAN_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[LOANS_EDIT_SET_STATE]( state, action ) {
		return setEditStateHelper( state, action, defaultLoansState );
	},
	[LOANS_EDIT_LOAN_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[LOANS_EDIT_LOAN_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultLoansState );
	},
	[LOANS_EDIT_LOAN_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[LOANS_DELETE_LOAN_SUCCESS]( state, action ) {
		return deleteSingleObjectFromState( state, action.meta.id );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultLoansState;
	},
});
