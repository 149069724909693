import { Dispatch } from 'redux';
import type { GetState } from '../types/actions';
import { post } from '../utils/rsaaHelpers';
import { isLoggedIn } from '../utils/authUtils';
import history from '../history';

// Tracks username/password as they are entered
export const AUTH_SET_USERNAME = 'AUTH_SET_USERNAME';
export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';

// Login API Calls
export const AUTH_LOGIN_BEGIN = 'AUTH_LOGIN_BEGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

// Logout API Calls
export const AUTH_LOGOUT_BEGIN = 'AUTH_LOGOUT_BEGIN';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

export const AUTH_IS_EXPIRED = 'AUTH_IS_EXPIRED';

export const authLogin = ( username: string, password: string ) => ( dispatch: Dispatch, getState: GetState ) => {
	dispatch( post(
		'/login',
		[ AUTH_LOGIN_BEGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE ],
		{ username, password }
	) ).then( () => {
		const { auth } = getState();

		if ( isLoggedIn( auth ) ) {
			dispatch( onLogin() );
		}
	});
};

export const onLogin = () => ( dispatch: Dispatch, getState: GetState ) => {
	// return Promise.all([
	// 	dispatch(accountsGetAccounts())
	// ]);
};

export const authLogout = () => ( dispatch: Dispatch, getState: GetState ) => {
	dispatch( post(
		'/logout',
		[ AUTH_LOGOUT_BEGIN, AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE ]
	) ).then( () => {
		const { auth } = getState();

		if ( false === isLoggedIn( auth ) ) {
			history.push( '/' );
		}
	});
};

export const authIsExpired = () => {
	return {
		type: AUTH_IS_EXPIRED,
	}
};

export const authSetUsername = ( username ) => {
	return {
		type: AUTH_SET_USERNAME,
		payload: {
			username
		}
	}
};

export const authSetPassword = ( password ) => {
	return {
		type: AUTH_SET_PASSWORD,
		payload: {
			password
		}
	}
};

