// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {AccountsState, authState, ProfileState, State} from '../../types/state';
import logo from '../../assets/images/logo.png';
import { authLogout } from '../../actions/auth';
import { isLoggedIn } from '../../utils/authUtils';
import { accountsSwitchAccount } from '../../actions/accounts';
import {appToggleMenu} from "../../actions/app";
import Gravatar from 'react-gravatar';

type Props = {
	auth: authState;
	authLogout: authLogout;
	accountsSwitchAccount: typeof accountsSwitchAccount;
	menuOpen: boolean;
	appToggleMenu: typeof appToggleMenu;
	accounts: AccountsState;
	profile: ProfileState;
};

type ComponentState = {

};

class MainNavigation extends Component<Props, ComponentState> {

	menuState() {
		return this.props.menuOpen === true ? 'is-active' : '';
	}

	loggedInLinks() {
		let activeAccount = this.props.accounts.objects[ this.props.accounts.activeAccount ];
		if ( isLoggedIn( this.props.auth ) && activeAccount ) {
			return (
				<>
					<Link to='/summary' className='navbar-item'>Summary</Link>
					<Link to='/income' className='navbar-item'>Income</Link>
					<Link to='/expenses' className='navbar-item'>Expenses</Link>
					<Link to='/loans' className='navbar-item'>Loans</Link>
					<Link to='/bank_accounts' className='navbar-item'>Bank Accounts</Link>
				</>
			)
		}
	}

	profileLinks() {
		if ( ! isLoggedIn( this.props.auth ) ) {
			return (
				<>
					<Link to='/login' className='navbar-item'>Login</Link>
					<Link to='register' className='navbar-item'>Register</Link>
				</>
			)
		} else {
			return (
				<div className={`navbar-item has-dropdown is-hoverable`}>
					<a className={`navbar-link`}>
						<Gravatar className={`is-hidden-touch`} size={28} email={this.props.profile.email} />
						<span className={`is-hidden-desktop`}>{this.props.profile.name}</span>
					</a>

					<div className={`navbar-dropdown is-right`}>
						<Link to="/profile" className={`navbar-item`}>Profile</Link>
						<span className="navbar-divider"></span>
						<Link to={`/accounts/manage`} className="navbar-item">Manage Account</Link>
						<a className="navbar-item" onClick={this.props.accountsSwitchAccount}>Switch Account</a>
						<span className="navbar-divider"></span>
						<a className="navbar-item" onClick={this.props.authLogout}>Log Out</a>
					</div>
				</div>
			)
		}
	}

	render() {
		let activeAccount = this.props.accounts.objects[ this.props.accounts.activeAccount ];

		return (
			<nav className="navbar is-dark is-fixed-top">
				<div className="navbar-brand">
					<Link to="/" className="navbar-item">
						<img alt={`Site Logo`} src={logo} />
					</Link>
					{ activeAccount && (
						<Link to="/summary" className="navbar-item">
							<strong>{activeAccount.name}</strong>
						</Link>
					)}


					<a role="button" className={`navbar-burger ${this.menuState()}`} aria-label="menu" aria-expanded="false" onClick={this.props.appToggleMenu}>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>
				<div className={`navbar-menu ${this.menuState()}`}>
					<div className={`navbar-start`}>
						{this.loggedInLinks()}
					</div>
					<div className={`navbar-end`}>
						{/*{this.adminLinks()}*/}
						{this.profileLinks()}
					</div>

				</div>
			</nav>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	auth: state.auth,
	menuOpen: state.app.menuOpen,
	accounts: state.accounts,
	profile: state.profile,
});

export const ConnectedMainNavigation = connect( mapStateToProps, mapDispatchToProps )( MainNavigation );
