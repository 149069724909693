// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	buttonText: string;
	deleteMethod: Function;
};

type ComponentState = {
	deleteTriggered: boolean;
};

export class DeleteConfirm extends Component<Props, ComponentState> {

	state = {
		deleteTriggered: false,
	};

	render() {
		if ( this.state.deleteTriggered === false ) {
			return (
				<div className="field is-grouped">
					<button className="button is-danger is-small" onClick={ () => { this.setState({deleteTriggered: true}) } }>
						<span className="icon">
							<FontAwesomeIcon icon={"trash"} />
						</span>
						<span>{this.props.buttonText}</span>
					</button>
				</div>
			)
		} else {
			return (
				<div className="field is-grouped">
					<div className="control">
						<button className="button is-small" onClick={()=>{this.setState({deleteTriggered: false})}}>Cancel</button>
					</div>
					<div className="control">
						<button className="button is-danger is-small" onClick={()=>{ this.props.deleteMethod(); this.setState({deleteTriggered: false}) }}>Confirm</button>
					</div>
				</div>
			)
		}
	}

}
