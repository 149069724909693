// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { incomeDeleteIncome, incomeGetIncome } from '../../actions/income';
import type { BankAccountsState, IncomeState, State } from '../../types/state';
import { returnSubsetById } from '../../utils/stateHelpers';
import {formatUSD, nl2br} from '../../utils/formattingHelpers';
import { FREQUENCY_LABELS, getPeriodEquivalent, getPeriodLabel, getPeriodSum } from '../../utils/frequencyHelpers';
import { TotalIncomeLevel } from './totalIncomeLevel';
import { bankAccountsGetAccounts } from '../../actions/bankAccounts';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteConfirm } from '../shared/buttons/deleteConfirm';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';

type Props = {
	incomeGetIncome: typeof incomeGetIncome;
	incomeDeleteIncome: typeof incomeDeleteIncome;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
	income: IncomeState;
	bankAccounts: BankAccountsState;
	period: string;
};

type ComponentState = {
	expandedIncome: number;
};

class Income extends Component<Props, ComponentState> {

	state = {
		expandedIncome: 0,
	};

	componentWillMount() {
		this.props.incomeGetIncome();
		this.props.bankAccountsGetAccounts();
	}

	getBankAccountName = ( accountID: number ) : string => {
		const { objects } = this.props.bankAccounts;

		if ( objects[ accountID ] ) {
			return objects[ accountID ].name;
		}

		return '';
	};

	handleToggle = ( id: number ) => {
		if ( this.state.expandedIncome === id ) {
			this.setState({ expandedIncome: 0 });
		} else {
			this.setState({ expandedIncome: id });
		}
	};

	render() {
		let incomes = returnSubsetById( this.props.income.ids, this.props.income.objects );

		return (
			<ConnectedSectionWrapper
				title="Income Details"
				periodToggle={true}
				render={() => (
					<>
						<div className="level">
							<TotalIncomeLevel totalIncome={getPeriodSum( incomes, this.props.period )}/>
						</div>

						<div className="add-expense has-text-right">
							<Link className="button is-primary" to="/income/add">
					<span className="icon">
						<FontAwesomeIcon icon="plus"/>
					</span>
								<span>Add Income</span>
							</Link>
						</div>

						<div className="incomes-list section has-stacked-panels">
							<div className='income columns legend is-mobile'>
								<strong className="column">Name</strong>
								<strong className="column is-hidden-mobile">Net Amount</strong>
								<strong className="column is-hidden-mobile">Gross Amount</strong>
								<strong className="column is-hidden-mobile">Frequency</strong>
								<strong className="column is-hidden-mobile">Bank Account</strong>
								<strong className="column has-text-right">{getPeriodLabel( this.props.period )} Total</strong>
							</div>

							{ incomes.map( income => {
								let isHidden = this.state.expandedIncome === income.id ? '' : 'is-hidden',
									isActive = this.state.expandedIncome === income.id ? 'is-active' : '';

								return (
									<div className={`panel ${isActive}`} key={income.id}>
										<div className="panel-heading has-interaction is-size-6 has-text-weight-normal" onClick={() => { this.handleToggle( income.id ) }}>
											<div className="columns is-mobile">
												<span className="column">{ income.name }</span>
												<span className="column is-hidden-mobile">{ formatUSD( income.amount ) }</span>
												<span className="column is-hidden-mobile">{ formatUSD( income.gross_income ) }</span>
												<span className="column is-hidden-mobile">{ FREQUENCY_LABELS[ income.frequency ].recurring }</span>
												<span className="column is-hidden-mobile">{ this.getBankAccountName( income.bank_account_id ) }</span>
												<span className="column has-text-right">{ formatUSD( getPeriodEquivalent( income, this.props.period ) ) }</span>
											</div>
										</div>

										<div className={`panel-block is-hidden-tablet ${isHidden} ${isActive}`}>
											<div className="field is-fullwidth">
												<span className="label is-inline">Paid { FREQUENCY_LABELS[ income.frequency ].recurring }:&nbsp;</span>
												<span>{ formatUSD( income.amount ) }</span>
											</div>
										</div>

										<div className={`panel-block is-hidden-tablet ${isHidden} ${isActive}`}>
											<div className="field is-fullwidth">
												<span className="label is-inline">Bank Account:&nbsp;</span>
												<span>{ this.getBankAccountName( income.bank_account_id ) }</span>
											</div>
										</div>

										{income.notes.length > 0 && (
											<div className={`panel-block ${isHidden} ${isActive}`}>
												<p className="content">
													{nl2br(income.notes)}
												</p>
											</div>
										)}

										<div className={`panel-block ${isHidden} ${isActive}`}>
											<div className="columns">
												<div className="column">
													<Link className="button is-small" to={`/income/edit/${income.id}`}>
														<span className="icon">
															<FontAwesomeIcon icon={'edit'}/>
														</span>
														<span>Edit Income</span>
													</Link>
												</div>
												<div className="column">
													<DeleteConfirm className={"is-centered"} buttonText="Delete Income" deleteMethod={() => { this.props.incomeDeleteIncome( income.id ) }} />
												</div>
											</div>
										</div>
									</div>
								)
							})}

							<p className="has-text-right">
								<strong>{formatUSD( getPeriodSum( incomes, this.props.period ) )}</strong>
							</p>
						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	income: state.income,
	bankAccounts: state.bankAccounts,
	period: state.app.period,
} );

export const ConnectedIncome = connect( mapStateToProps, mapDispatchToProps )( Income );
