// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { IncomeAddState, State } from '../../types/state';
import { incomeAddIncome, incomeSetStateForEdit } from '../../actions/income';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { ConnectedIncomeForm } from './form';

type Props = {
	addState: IncomeAddState;
	adding: boolean;
	errors: string[];
	incomeAddIncome: typeof incomeAddIncome;
	incomeSetStateForEdit: typeof incomeSetStateForEdit;
	editing: number;
};

class AddIncome extends Component<Props> {

	componentWillMount() {
		// Reset the state if we were previously editing an expense and haven't cleared yet
		if ( this.props.editing !== 0 ) {
			this.props.incomeSetStateForEdit( 0, {} );
		}
	}

	render() {
		return (
			<ConnectedSectionWrapper
				title="Add Income"
				render={() => (
					<ConnectedIncomeForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.props.incomeAddIncome}
					/>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.income.add,
	adding: state.income.adding,
	errors: state.income.errors,
	editing: state.income.editing,
});

export const ConnectedAddIncome = connect( mapStateToProps, mapDispatchToProps )(AddIncome);
