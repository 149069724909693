// @flow

import { Dispatch } from 'redux';
import type { Action, GetState } from '../types/actions';
import { get, post, put, del } from '../utils/rsaaHelpers';
import history from '../history';

export const BANK_ACCOUNTS_GET_ACCOUNTS_BEGIN = 'BANK_ACCOUNTS_GET_ACCOUNTS_BEGIN';
export const BANK_ACCOUNTS_GET_ACCOUNTS_SUCCESS = 'BANK_ACCOUNTS_GET_ACCOUNTS_SUCCESS';
export const BANK_ACCOUNTS_GET_ACCOUNTS_FAILURE = 'BANK_ACCOUNTS_GET_ACCOUNTS_FAILURE';

export const BANK_ACCOUNTS_ADD_ACCOUNT_BEGIN = 'BANK_ACCOUNTS_ADD_ACCOUNT_BEGIN';
export const BANK_ACCOUNTS_ADD_ACCOUNT_SUCCESS = 'BANK_ACCOUNTS_ADD_ACCOUNT_SUCCESS';
export const BANK_ACCOUNTS_ADD_ACCOUNT_FAILURE = 'BANK_ACCOUNTS_ADD_ACCOUNT_FAILURE';

export const BANK_ACCOUNTS_EDIT_SET_STATE = 'BANK_ACCOUNTS_EDIT_SET_STATE';

export const BANK_ACCOUNTS_EDIT_ACCOUNT_BEGIN = 'BANK_ACCOUNTS_EDIT_ACCOUNT_BEGIN';
export const BANK_ACCOUNTS_EDIT_ACCOUNT_SUCCESS = 'BANK_ACCOUNTS_EDIT_ACCOUNT_SUCCESS';
export const BANK_ACCOUNTS_EDIT_ACCOUNT_FAILURE = 'BANK_ACCOUNTS_EDIT_ACCOUNT_FAILURE';

export const BANK_ACCOUNTS_DELETE_ACCOUNT_BEGIN = 'BANK_ACCOUNTS_DELETE_ACCOUNT_BEGIN';
export const BANK_ACCOUNTS_DELETE_ACCOUNT_SUCCESS = 'BANK_ACCOUNTS_DELETE_ACCOUNT_SUCCESS';
export const BANK_ACCOUNTS_DELETE_ACCOUNT_FAILURE = 'BANK_ACCOUNTS_DELETE_ACCOUNT_FAILURE';

// Tracks fields as they are entered
export const BANK_ACCOUNTS_SET_ADD_FIELD = 'BANK_ACCOUNTS_SET_ADD_FIELD';

export const bankAccountsGetAccounts = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( get(
		`/accounts/${activeAccount}/bank_accounts`,
		[ BANK_ACCOUNTS_GET_ACCOUNTS_BEGIN, BANK_ACCOUNTS_GET_ACCOUNTS_SUCCESS, BANK_ACCOUNTS_GET_ACCOUNTS_FAILURE ]
	) );
};

export const bankAccountsAddAccount = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().bankAccounts;
	const { activeAccount } = getState().accounts;

	dispatch( post(
		`/accounts/${activeAccount}/bank_accounts`,
		[ BANK_ACCOUNTS_ADD_ACCOUNT_BEGIN, BANK_ACCOUNTS_ADD_ACCOUNT_SUCCESS, BANK_ACCOUNTS_ADD_ACCOUNT_FAILURE ],
		add
	)).then( () => {
		const { errors } = getState().bankAccounts;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/bank_accounts' );
		}
	});
};

export const bankAccountsSetStateForEdit = ( id: number, state: {} ) : Action => {
	return {
		type: BANK_ACCOUNTS_EDIT_SET_STATE,
		payload: {
			id,
			state
		}
	}
};

export const bankAccountsEditAccount = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().bankAccounts;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( put(
		`/accounts/${activeAccount}/bank_accounts/${id}`,
		[ BANK_ACCOUNTS_EDIT_ACCOUNT_SUCCESS, BANK_ACCOUNTS_EDIT_ACCOUNT_SUCCESS, BANK_ACCOUNTS_EDIT_ACCOUNT_FAILURE ],
		{
			...add,
			budget_id: activeBudget
		}
	)).then( () => {
		const { errors } = getState().bankAccounts;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/bank_accounts' );
		}
	});
};

export const bankAccountsDeleteAccount = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( del(
		`/accounts/${activeAccount}/bank_accounts/${id}`,
		[ BANK_ACCOUNTS_DELETE_ACCOUNT_BEGIN, BANK_ACCOUNTS_DELETE_ACCOUNT_SUCCESS, BANK_ACCOUNTS_DELETE_ACCOUNT_FAILURE ],
		{},
		{id}
	));
};

export const bankAccountsSetField = ( field: string, value: any ) => {
	return {
		type: BANK_ACCOUNTS_SET_ADD_FIELD,
		payload: {
			field,
			value
		}
	}
};
