// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {ProfileState, State} from "../../types/state";
import {ConnectedSectionWrapper} from "../shared/section-wrapper";
import Gravatar from 'react-gravatar';
import { accountsSwitchAccount } from '../../actions/accounts';
import history from '../../history';

type Props = {
	profile: ProfileState;
	accountsSwitchAccount: typeof accountsSwitchAccount;
}

export class Profile extends Component<Props> {

	switchAccount = ( event : Event ) => {
		event.preventDefault();

		this.props.accountsSwitchAccount();

		// So that we show the account switcher
		history.push( '/summary' );
	};

	render() {
		const { profile } = this.props;

		return (
			<ConnectedSectionWrapper
				title={"Profile"}
				render={() => (
					<>
						{this.props.profile.inviteAccepted === true && (
							<div className="message is-success">
								<div className="message-body">
									Invitation accepted. You may switch the new account from the <a href="#switch-account" onClick={this.switchAccount}>Switch Account</a> screen.
								</div>
							</div>
						)}
						<div className="level">
							<div className="level-left">
								<div className="level-item">
									<Gravatar size={100} email={profile.email} />
								</div>
								<div className="level-item">
									<div>
										<h2 className="subtitle has-text-centered-mobile">{profile.name}</h2>
										<p className={`has-text-centered-mobile`}>{profile.username}</p>
										<p className="has-text-centered-mobile">{profile.email}</p>
									</div>
								</div>
							</div>
							<div className="level-right">

							</div>
						</div>

					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	profile: state.profile,
});

export const ConnectedProfile = connect( mapStateToProps, mapDispatchToProps )( Profile );
