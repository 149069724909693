import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';
import { apiMiddleware } from 'redux-api-middleware';
import apiDupePromise from './middleware/api-dupe-promise';
import {
	defaultAuthState
} from './reducers/auth';
import dotProp from 'dot-prop-immutable';
import { clearLocalStorageAuth, getLocalStorageAuth } from './utils/authUtils';
import { clearLocalStorageActiveAccount, getLocalStorageActiveAccount } from './utils/accountUtils';
import { clearLocalStorageActiveBudget, getLocalStorageActiveBudget } from './utils/budgetUtils';
import { defaultAccountsState } from './reducers/accounts';
import { defaultBudgetsState } from './reducers/budgets';
import { clearLocalStorageActivePeriod, getLocalStorageActivePeriod } from './utils/appUtils';
import { defaultAppState } from './reducers/app';

const middlewares = [
	thunk,
	apiDupePromise,
	apiMiddleware,
];

// if ( 'development' === process.env.NODE_ENV ) {
// 	const { logger } = require( 'redux-logger' );
// 	middlewares.push( logger );
// }

export default function configureStore() {
	let preloadedState = {};

	// Check for access token in localstorage
	let { accessToken, accessTokenExpiration } = getLocalStorageAuth(),
		activeAccount = getLocalStorageActiveAccount(),
		activeBudget = getLocalStorageActiveBudget(),
		activePeriod = getLocalStorageActivePeriod();

	if ( null !== accessToken && accessTokenExpiration > Date.now() ) {
		preloadedState = dotProp.set( preloadedState, 'auth', defaultAuthState );
		preloadedState = dotProp.set( preloadedState, 'auth.accessToken', accessToken );
		preloadedState = dotProp.set( preloadedState, 'auth.accessTokenExpiration', accessTokenExpiration );

		if ( null !== activeAccount ) {
			preloadedState = dotProp.set( preloadedState, 'accounts', defaultAccountsState );
			preloadedState = dotProp.set( preloadedState, 'accounts.activeAccount', activeAccount );
		}

		if ( null !== activeBudget ) {
			preloadedState = dotProp.set( preloadedState, 'budgets', defaultBudgetsState );
			preloadedState = dotProp.set( preloadedState, 'budgets.activeBudget', activeBudget );
		}

		if ( null !== activePeriod ) {
			preloadedState = dotProp.set( preloadedState, 'app', defaultAppState );
			preloadedState = dotProp.set( preloadedState, 'app.period', activePeriod );
		}
	} else {
		// Expired - clear the token
		clearLocalStorageAuth();
		clearLocalStorageActiveAccount(); // Don't want things to get out of sync
		clearLocalStorageActiveBudget(); // Don't want things to get out of sync
		clearLocalStorageActivePeriod(); // Don't want things to get out of sync
	}

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(
		rootReducer,
		preloadedState,
		composeEnhancers( applyMiddleware( ...middlewares )
	) );
}
