// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { InvitesState, State } from '../../types/state';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { profileAcceptInvite, profileDeclineInvite, profileGetInvites } from '../../actions/profile';
import { returnSubsetById } from '../../utils/stateHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	invites: InvitesState;
	profileGetInvites: typeof profileGetInvites;
	profileAcceptInvite: typeof profileAcceptInvite;
	profileDeclineInvite: typeof profileDeclineInvite;
};

export class ProfileInvites extends Component<Props> {

	componentWillMount() {
		this.loadData();
	}

	componentWillUpdate() {

	}

	loadData = () => {
		this.props.profileGetInvites();
	};

	render() {
		let invites = returnSubsetById( this.props.invites.ids, this.props.invites.objects );

		return (
			<ConnectedSectionWrapper
				title={"Your Invitations"}
				render={() => (
					<div>
						{invites.length > 0 && invites.map( invite => (
							<div className="box level" key={invite.id}>
								<div className="level-left">
									{invite.account_name}
									</div>
									<div className="level-right">
										<button className="button is-success level-item" onClick={() => { this.props.profileAcceptInvite( invite.id ); }}>
											<span className="icon is-small">
												<FontAwesomeIcon icon="check" />
											</span>
											<span>Accept</span>
										</button>
										<button className="button is-danger level-item" onClick={() => { this.props.profileDeclineInvite( invite.id ); }}>
											<span className="icon is-small">
												<FontAwesomeIcon icon="trash" />
											</span>
											<span>Decline</span>
										</button>
									</div>
							</div>
						))}
						{invites.length === 0 && (
							<div className="content has-text-centered">
								<p>You do not have any invites at this time.</p>
							</div>
						)}
					</div>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	invites: state.profile.invites,
} );

export const ConnectedProfileInvites = connect( mapStateToProps, mapDispatchToProps )( ProfileInvites );
