// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import {usersInviteUser} from "../../actions/users";
import type { AccountsState, State, UsersState } from "../../types/state";
import { ConnectedSectionWrapper } from "../shared/section-wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import history from "../../history";
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	usersInviteUser: typeof usersInviteUser;
	accounts: AccountsState;
	users: UsersState;
}

type ComponentState = {
	email: string;
};

class InviteUser extends Component<Props, ComponentState> {

	state = {
		email: ''
	};

	invite = () => {
		this.props.usersInviteUser( this.state.email ).then(() => {
			if ( Object.keys( this.props.users.errors ).length === 0 ) {
				history.push( '/accounts/manage?invited=1' );
			}
		});
	};

	render() {
		let account = this.props.accounts.objects[ this.props.accounts.activeAccount ];
		let isLoading = this.props.users.adding === true ? 'is-loading' : '';
		const { errors } = this.props.users;

		return (
			<ConnectedSectionWrapper
				title="Invite User"
				subtitle={account && account.name ? account.name : '' }
				render={() => (
					<>
						<form className="section" onSubmit={event => {
							event.preventDefault();
							this.invite();
						}}>
							<div className={`field`}>
								<label htmlFor="email" className="label">Email</label>
								<div className="control has-icons-left">
									<input id={`email`} className={`input ${formFieldErrorClass(errors, 'email')} ${formFieldErrorClass(errors, 'generic')}`} placeholder={`Email`} type="text"
										   value={this.state.email}
										   onChange={event => {
											   this.setState( { email: event.target.value } )
										   }}
									/>
									<span className={`icon is-small is-left`}>
										<FontAwesomeIcon icon="envelope"/>
									</span>
								</div>
								<p className="help is-danger">{formErrorsForField(errors, 'email')}</p>
								<p className="help is-danger">{formErrorsForField(errors, 'generic')}</p>
							</div>

							<div className="field">
								<button className={`button is-info ${isLoading}`} onClick={this.invite}>Invite</button>
							</div>
						</form>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	accounts: state.accounts,
	users: state.users,
});

export const ConnectedInviteUser = connect( mapStateToProps, mapDispatchToProps )( InviteUser );
