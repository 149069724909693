// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { BankAccountsState, ExpensesAddState, State } from '../../types/state';
import { FREQUENCY_LABELS } from '../../utils/frequencyHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bankAccountsGetAccounts } from '../../actions/bankAccounts';
import { expensesAddSplit, expensesDeleteSplit, expensesSetField } from '../../actions/expenses';
import { BankAccountDropdown } from '../bankAccounts/dropdown';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	// Required to be passed:
	addState: ExpensesAddState;
	adding: boolean;
	errors: string[];
	saveCallback: () => void;

	// Redux:
	expensesAddSplit: typeof expensesAddSplit;
	expensesDeleteSplit: typeof expensesDeleteSplit;
	expensesSetField: typeof expensesSetField;
	bankAccounts: BankAccountsState;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
};

export class ExpenseForm extends Component<Props> {

	componentWillMount() {
		this.props.bankAccountsGetAccounts();
	}

	render() {
		let disabled = false,
			isLoading = '';
		if ( this.props.adding ) {
			disabled = true;
			isLoading = 'is-loading';
		}

		let totalAmount = 0;
		this.props.addState.children.forEach( child => {
			totalAmount += parseFloat( child.amount )
		} );

		let mainAmountDisabled = this.props.addState.children.length > 0,
			mainAmount = mainAmountDisabled ? totalAmount : this.props.addState.amount;

		const { errors } = this.props;

		return (
			<form className="expenses-form section" onSubmit={ event => { event.preventDefault(); this.props.saveCallback(); }}>

				<div className="columns">
					<div className="field column">
						<label htmlFor="name" className="label">Name</label>
						<div className="control">
							<input type="text" id="name" className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder="Name" onChange={event => {this.props.expensesSetField( 'name', event.target.value )}} value={this.props.addState.name} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
					</div>
				</div>

				<div className="columns">
					<div className="field column is-half">
						<label htmlFor="date" className="label">Date</label>
						<div className="control">
							<input type="text" id="amount" className={`input ${formFieldErrorClass(errors, 'date')}`} placeholder="Date" onChange={event => {this.props.expensesSetField( 'date', event.target.value)}} value={this.props.addState.date}/>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'date')}</p>
					</div>
					<div className="field column is-half">
						<label htmlFor="amount" className="label">Amount</label>
						<div className="control">
							<input type="text" id="amount" disabled={mainAmountDisabled} className={`input ${formFieldErrorClass(errors, 'amount')}`} placeholder="Amount" onChange={event => {this.props.expensesSetField( 'amount', event.target.value )}} value={mainAmount} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'amount')}</p>
					</div>
				</div>

				<div className="columns">
					<BankAccountDropdown
						bankAccounts={this.props.bankAccounts}
						selected={this.props.addState.bank_account_id}
						selectCallback={(value) => {
							this.props.expensesSetField( 'bank_account_id', value );
						}}
						extraClasses="column is-one-quarter"
						errors={errors}
					/>

					<div className="field column is-one-quarter">
						<label htmlFor="frequency" className="label">Frequency</label>
						<div className="control">
							<div className={`select is-fullwidth ${formFieldErrorClass(errors, 'frequency')}`}>
								{/*// @todo should be component probably */}
								<select name="frequency" id="frequency" value={this.props.addState.frequency} onChange={event => {this.props.expensesSetField( 'frequency', event.target.value )}}>
									<option>&mdash; Select Frequency &mdash;</option>
									{Object.keys( FREQUENCY_LABELS ).map( function( frequency ) {
										return (
											<option key={frequency} value={frequency}>{FREQUENCY_LABELS[frequency].recurring}</option>
										)
									})}
								</select>
							</div>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'frequency')}</p>
					</div>

					<div className="field column is-one-quarter">
						<div className="label">&nbsp;</div>
						<div className="control">
							<label className="checkbox">
								<input type="checkbox" checked={this.props.addState.autopay} onChange={ event => { this.props.expensesSetField( 'autopay', event.target.checked )}} />
								&nbsp; Autopay
							</label>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'autopay')}</p>
					</div>
				</div>

				<div className="field split-container box">
					<h2 className="subtitle">Splits</h2>

					{this.props.addState.children.length > 0 && this.props.addState.children.map( ( child, index ) => {
						return (
							<div className="columns" key={index}>
								<div className="field column is-6">
									<label htmlFor="name" className="label">Name</label>
									<div className="control">
										<input type="text" id="name" className={`input ${formFieldErrorClass(errors, `children.${index}.name`)}`} placeholder="Name" onChange={event => {this.props.expensesSetField( `children.${index}.name`, event.target.value )}} value={child.name} />
									</div>
									<p className="help is-danger">{formErrorsForField(errors, `children.${index}.name`)}</p>
								</div>
								<div className="field column">
									<label htmlFor="amount" className="label">Amount</label>
									<div className="control">
										<input type="text" id="amount" className={`input ${formFieldErrorClass(errors, `children.${index}.amount`)}`} placeholder="Amount" onChange={event => {this.props.expensesSetField( `children.${index}.amount`, event.target.value )}} value={child.amount} />
									</div>
									<p className="help is-danger">{formErrorsForField(errors, `children.${index}.amount`)}</p>
								</div>
								<div className="field column is-narrow">
									<label className="label">&nbsp;</label>
									<div className="control">
										<a href="#delete-split" className="button is-danger is-outlined" onClick={( event ) => {
											event.preventDefault();
											this.props.expensesDeleteSplit( index );
										}}>
											<span className="icon">
												<FontAwesomeIcon icon="trash"/>
											</span>
											<span>Delete</span>
										</a>
									</div>
								</div>
							</div>
						)
					})}

					<div className="add-split">
						<a className="button is-small" onClick={this.props.expensesAddSplit}>
							<span className="icon">
								<FontAwesomeIcon icon="plus"/>
							</span>
							<span>Add Split</span>
						</a>
					</div>
				</div>

				<div className="field">
					<label htmlFor="notes" className="label">Notes</label>
					<div className="control">
						<textarea className={`textarea ${formFieldErrorClass(errors, 'notes')}`} name="notes" id="notes" rows="6" onChange={event => {this.props.expensesSetField('notes', event.target.value)}} value={this.props.addState.notes}></textarea>
					</div>
					<p className="help is-danger">{formErrorsForField(errors, 'notes')}</p>
				</div>

				<div className={`field is-grouped`}>
					<div className={`control`}>
						<button disabled={disabled} className={`button is-primary ${isLoading}`} onClick={this.props.saveCallback}>
							Save
						</button>
					</div>
				</div>

			</form>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	bankAccounts: state.bankAccounts,
});

export const ConnectedExpenseForm = connect( mapStateToProps, mapDispatchToProps )( ExpenseForm );
