// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../utils/dispatchToProps';
import type { State } from '../types/state';
import { ConnectedSectionWrapper } from './shared/section-wrapper';
import { Link } from 'react-router-dom';

class Home extends Component<void> {

	render() {
		return (
			<ConnectedSectionWrapper
				title="YNAPlan"
				render={ ()=> (
					<div className="content has-text-centered">
						<p>Welcome! Please <Link to="/login">Log In</Link></p>


					</div>
				) }
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({

});

export const ConnectedHome = connect( mapStateToProps, mapDispatchToProps )( Home );
