// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { Match } from 'react-router-dom';
import { returnSubsetByIdOrParent } from '../../utils/stateHelpers';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { ConnectedBankAccountForm } from './form';
import type { BankAccountsAddState, BankAccountsState, ErrorsState, State } from '../../types/state';
import {
	bankAccountsEditAccount,
	bankAccountsGetAccounts,
	bankAccountsSetStateForEdit
} from '../../actions/bankAccounts';

type Props = {
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
	bankAccountsSetStateForEdit: typeof bankAccountsSetStateForEdit;
	bankAccountsEditAccount: typeof bankAccountsEditAccount;
	addState: BankAccountsAddState;
	adding: boolean;
	errors: ErrorsState;
	match: Match;
	bankAccounts: BankAccountsState;
};

class EditBankAccount extends Component<Props> {

	componentWillMount() {
		this.initialLoad();
	}

	componentDidUpdate() {
		this.initialLoad();
	}

	initialLoad = () => {
		this.props.bankAccountsGetAccounts();

		this.setEditState();
	};

	setEditState = () => {
		if ( this.props.bankAccounts.ids.indexOf( this.getId() ) === -1 ) {
			return false;
		}

		if ( this.props.bankAccounts.editing === this.getId() ) {
			return false;
		}

		let bankAccountState = returnSubsetByIdOrParent( this.getId(), this.props.bankAccounts.objects );
		bankAccountState = bankAccountState[0];

		this.props.bankAccountsSetStateForEdit( this.getId(), bankAccountState );
	};

	getId = () : number => {
		let { id } = this.props.match.params;

		id = 'string' === typeof id ? id : '';

		// get rid of bad characters
		id = id.replace( '\ufeff', '' );

		return parseInt( id, 10 );
	};

	save = () => {
		this.props.bankAccountsEditAccount( this.getId() );
	};

	render() {
		return (
			<ConnectedSectionWrapper
				title="Edit Bank Account"
				render={() => (
					<ConnectedBankAccountForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.save}
					/>
				)}
			/>
		)
	}
}

const mapStateToProps = ( state: State ) => ({
	addState: state.bankAccounts.add,
	adding: state.bankAccounts.adding,
	errors: state.bankAccounts.errors,
	bankAccounts: state.bankAccounts,
});

export const ConnectedEditBankAccount = connect( mapStateToProps, mapDispatchToProps )( EditBankAccount );
