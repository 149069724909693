// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';
import type { BankAccountsAddState, ErrorsState, State } from '../../types/state';
import { bankAccountsSetField } from '../../actions/bankAccounts';

type Props = {
	// Required to be passed
	addState: BankAccountsAddState;
	adding: boolean;
	errors: ErrorsState;
	saveCallback: () => void;

	// Redux
	bankAccountsSetField: typeof bankAccountsSetField;
}

class BankAccountForm extends Component<Props> {

	render() {
		let disabled = false,
			isLoading = '';
		if ( this.props.adding ) {
			disabled = true;
			isLoading = 'is-loading';
		}

		const { errors } = this.props;

		return (
			<form className="bankaccounts-form section" onSubmit={ event => { event.preventDefault(); this.props.saveCallback(); }}>
				<div className="columns">
					<div className="field column">
						<label htmlFor="name" className="label">Name</label>
						<div className="control">
							<input type="text" id="name" className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder="Name" onChange={event => {this.props.bankAccountsSetField( 'name', event.target.value )}} value={this.props.addState.name} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
					</div>
				</div>

				<div className={`field is-grouped`}>
					<div className={`control`}>
						<button disabled={disabled} className={`button is-primary ${isLoading}`} onClick={this.props.saveCallback}>
							Add
						</button>
					</div>
				</div>
			</form>
		)
	}

}

const mapStateToProps = ( state: State ) => ({

});

export const ConnectedBankAccountForm = connect( mapStateToProps, mapDispatchToProps )( BankAccountForm );
