// @flow

import React, { Component } from 'react';
import {formatPercent} from "../../utils/formattingHelpers"

type Props = {
	totalIncome: number;
	totalExpenses: number;
	minimumLoans: number;
	extraLoans: number;
};

export class PercentIncomeRemainingLevel extends Component<Props> {

	render() {
		const { totalIncome, totalExpenses, minimumLoans, extraLoans } = this.props;
		let remainingIncomeTotal = totalIncome - totalExpenses - minimumLoans - extraLoans,
			remainingIncomeMinimumOnly = totalIncome - totalExpenses - minimumLoans,
			remainingPercent = 0,
			remainingPercentMinimumOnly = 0,
			color = '',
			colorMinimumOnly = '';

		// Because who doesn't want an ∞ in an edge case
		if ( 0 === totalIncome && 0 === ( totalExpenses + minimumLoans + extraLoans ) ) {
			remainingPercent = "∞";
			color = 'primary-dark';
		} else if ( 0 === totalIncome && ( totalExpenses + minimumLoans + extraLoans ) > 0 ) {
			remainingPercent = "-∞";
			color = 'danger-dark';
		} else {
			remainingPercent = Math.round( ( remainingIncomeTotal / totalIncome ) * 100 )
			color = remainingPercent >= 0 ? 'primary-dark' : 'danger-dark';
		}

		// Because who doesn't want an ∞ in an edge case
		if ( 0 === totalIncome && 0 === ( totalExpenses + minimumLoans ) ) {
			remainingPercentMinimumOnly = "∞";
			colorMinimumOnly = 'primary-dark';
		} else if ( 0 === totalIncome && ( totalExpenses + minimumLoans ) > 0 ) {
			remainingPercentMinimumOnly = "-∞";
			colorMinimumOnly = 'danger-dark';
		} else {
			remainingPercentMinimumOnly = Math.round( ( remainingIncomeMinimumOnly / totalIncome ) * 100 )
			colorMinimumOnly = remainingPercentMinimumOnly >= 0 ? 'info-dark' : 'danger-dark';
		}

		return (
			<div className="level-item has-text-centered">
				<div>
					<p className={`title is-size-4-touch`}>
						<span className={`has-text-${color}`}>{ formatPercent(parseFloat(remainingPercent)) }</span>
						<span className="divider has-text-grey"> / </span>
						<span className={`has-text-${colorMinimumOnly}`}>{ formatPercent(parseFloat(remainingPercentMinimumOnly)) }</span>
					</p>
					<p className="heading">% Income Remaining</p>
				</div>
			</div>
		)
	}

}
