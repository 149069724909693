// @flow

import type {LoanSingleObject} from "../types/objects"

export type LoanStats = {
	paymentsRemaining: number|string;
	totalInterest: number;
};

const calculateLoanStats = ( loan: LoanSingleObject ) : LoanStats => {
	const totalPayment = parseFloat(loan.minimum_payment) + parseFloat(loan.extra_payment),
		monthlyInterest = parseFloat(loan.interest_rate)/100/12;

	let currentBalance = parseFloat(loan.amount),
		numberMonths = 0,
		totalInterest = 0.00;

	if ( totalPayment === 0 ) {
		return {
			paymentsRemaining: "∞",
			totalInterest: 0.0,
		};
	}
	do {
		numberMonths++;
		const currentInterest = monthlyInterest * currentBalance;
		totalInterest = totalInterest + currentInterest;

		currentBalance = currentBalance - (totalPayment - currentInterest)
	} while ( currentBalance > 0 )

	return {
		paymentsRemaining: numberMonths,
		totalInterest: totalInterest,
	};
};

export const CalculateNumberOfPaymentsLeft = ( loan: LoanSingleObject ) : number|string => {
	const loanStats = calculateLoanStats(loan)

	return loanStats.paymentsRemaining;
};

export const CalculateSavedInterestFromExtraPayments = ( loan: LoanSingleObject ) : number => {
	const actualLoanStats = calculateLoanStats(loan)

	const loan2 = {
		minimum_payment: loan.minimum_payment,
		extra_payment: 0.0,
		interest_rate: loan.interest_rate,
		amount: loan.amount,
	};
	const noExtraLoanStats = calculateLoanStats(loan2)

	return noExtraLoanStats.totalInterest - actualLoanStats.totalInterest
};
