// @flow

import React, { Component } from 'react';

import ChartComponent from 'react-chartjs-2';

type Props = {
	netDTI: number;
	grossDTI: number;
};

export class DTIChart extends Component<Props> {

	render() {
		const { netDTI, grossDTI } = this.props;

		const options = {
			tooltips: {
				enabled: false,
			},
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			scales: {
				yAxes: [{
					display: true,
					ticks: {
						suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
						suggestedMax: 100,
					}
				}]
			}
		};

		const data = {
			datasets: [
				{
					data: [grossDTI, netDTI],
					backgroundColor: ["#947600", "#cc0f35"],

				},
				{
					label: 'Mortgage Max DTI',
					data: [43, 43],
					type: 'line'
				},
			],
			labels: ["Gross", "Net"],
		};

		return (
			<div style={{width: 225, height: 225}}>
				<ChartComponent type="bar" data={data} options={options}/>
			</div>

		)
	}

}
