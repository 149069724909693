import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	BUDGETS_GET_BUDGETS_SUCCESS,
	BUDGETS_SET_ACTIVE_BUDGET,
} from '../actions/budgets';
import { objectDataToState } from '../utils/stateHelpers';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';
import { clearLocalStorageActiveBudget, setLocalStorageActiveBudget } from '../utils/budgetUtils';

export const defaultBudgetsState = {
	objects: {},
	ids: [],
	activeBudget: 0,
};

export const budgets = createReducer( defaultBudgetsState, {
	[BUDGETS_GET_BUDGETS_SUCCESS]( state, action ) {
		state = objectDataToState( state, action );

		if ( state.ids.length === 0 ) {
			state = dotProp.set( state, 'activeBudget', 0 );
			clearLocalStorageActiveBudget();
		}

		return state;
	},
	[BUDGETS_SET_ACTIVE_BUDGET]( state, action ) {
		state = dotProp.set( state, 'activeBudget', action.payload.id );

		setLocalStorageActiveBudget( action.payload.id );

		return state;
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultBudgetsState;
	}
});
