// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { BankAccountsState, IncomeAddState, State } from '../../types/state';
import { bankAccountsGetAccounts } from '../../actions/bankAccounts';
import { FREQUENCY_LABELS } from '../../utils/frequencyHelpers';
import { incomeSetField } from '../../actions/income';
import { BankAccountDropdown } from '../bankAccounts/dropdown';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	// Required to be passed
	addState: IncomeAddState;
	adding: boolean;
	errors: string[];
	saveCallback: () => void;

	// Redux
	incomeSetField: typeof incomeSetField;
	bankAccounts: BankAccountsState;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
};

class IncomeForm extends Component<Props> {

	componentWillMount() {
		this.props.bankAccountsGetAccounts();
	}

	render() {
		let disabled = false,
			isLoading = '';
		if ( this.props.adding ) {
			disabled = true;
			isLoading = 'is-loading';
		}

		const { errors } = this.props;

		return (
			<form className="income-form section" onSubmit={ event => { event.preventDefault(); this.props.saveCallback(); }}>
				<div className="columns">
					<div className="field column">
						<label htmlFor="name" className="label">Name</label>
						<div className="control">
							<input type="text" id="name" className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder="Name" onChange={event => {this.props.incomeSetField( 'name', event.target.value )}} value={this.props.addState.name} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
					</div>
				</div>

				<div className="columns">
					<div className="field column is-one-quarter">
						<label htmlFor="amount" className="label">Net Amount</label>
						<div className="control">
							<input type="text" id="amount" className={`input ${formFieldErrorClass(errors, 'amount')}`} placeholder="Net Amount" onChange={event => {this.props.incomeSetField( 'amount', event.target.value )}} value={this.props.addState.amount} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'amount')}</p>
						<p className="help">Enter net income (after taxes and other deductions).</p>
					</div>

					<div className="field column is-one-quarter">
						<label htmlFor="gross_income" className="label">Gross Amount</label>
						<div className="control">
							<input type="text" id="gross_income" className={`input ${formFieldErrorClass(errors, 'gross_income')}`} placeholder="Gross Amount" onChange={event => {this.props.incomeSetField( 'gross_income', event.target.value )}} value={this.props.addState.gross_income} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'gross_income')}</p>
						<p className="help">Enter gross income (before taxes and other deductions).</p>
					</div>

					<div className="field column is-one-quarter">
						<label htmlFor="frequency" className="label">Frequency</label>
						<div className="control">
							<div className={`select is-fullwidth ${formFieldErrorClass(errors, 'frequency')}`}>
								<select name="frequency" id="frequency" value={this.props.addState.frequency} onChange={event => {this.props.incomeSetField( 'frequency', event.target.value )}}>
									<option>&mdash; Select Frequency &mdash;</option>
									{Object.keys( FREQUENCY_LABELS ).map( function( frequency ) {
										return (
											<option key={frequency} value={frequency}>{FREQUENCY_LABELS[frequency].recurring}</option>
										)
									})}
								</select>
							</div>
							<p className="help is-danger">{formErrorsForField(errors, 'frequency')}</p>
						</div>
					</div>

					<BankAccountDropdown
						bankAccounts={this.props.bankAccounts}
						selected={this.props.addState.bank_account_id}
						selectCallback={(value) => {
							this.props.incomeSetField( 'bank_account_id', value );
						}}
						extraClasses="column is-one-quarter"
						errors={errors}
					/>
				</div>

				<div className="field">
					<label htmlFor="notes">Notes</label>
					<div className="control">
						<textarea className={`textarea ${formFieldErrorClass(errors, 'notes')}`} name="notes" id="notes" rows="6" onChange={event => {this.props.incomeSetField('notes', event.target.value)}} value={this.props.addState.notes}></textarea>
					</div>
					<p className="help is-danger">{formErrorsForField(errors, 'notes')}</p>
				</div>

				<div className={`field is-grouped`}>
					<div className={`control`}>
						<button disabled={disabled} className={`button is-primary ${isLoading}`} onClick={this.props.saveCallback}>
							Save
						</button>
					</div>
				</div>
			</form>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	bankAccounts: state.bankAccounts,
});

export const ConnectedIncomeForm = connect( mapStateToProps, mapDispatchToProps )( IncomeForm );
