import createReducer from '../utils/createReducer';

import {
	REGISTER_BEGIN,
	REGISTER_FAILURE,
	REGISTER_SUCCESS,
} from '../actions/registration';

let initialState = {
	loading: false,
	error: '',
	success: false,
	errors: {},
};

export const registration = createReducer( initialState, {
	[REGISTER_BEGIN](state,action) {
		return {
			...initialState,
			loading: true,
		};
	},
	[REGISTER_FAILURE](state,action) {
		return {
			...state,
			loading: false,
			error: true,
			errors: action.payload.response,
		};
	},
	[REGISTER_SUCCESS](state,action) {
		return {
			...state,
			loading: false,
			success: true,
		};
	},
});
