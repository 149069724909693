// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { State } from '../../types/state';

export class SiteFooter extends Component<void> {
	render() {
		return (
			<footer className="footer budget-site-footer">
				<p>Footer!</p>
			</footer>
		)
	}
}

const mapStateToProps = ( state: State ) => ( {

} );

export const ConnectedSiteFooter = connect( mapStateToProps, mapDispatchToProps )( SiteFooter );
