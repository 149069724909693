// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {BankAccountsState, ErrorsState, LoansAddState, LoansState, State} from '../../types/state';
import { loansGetLoans, loansSetField } from '../../actions/loans';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';
import {BankAccountDropdown} from "../bankAccounts/dropdown"
import {FREQUENCY_LABELS, MONTHLY} from "../../utils/frequencyHelpers"
import {bankAccountsGetAccounts} from "../../actions/bankAccounts"

type Props = {
	// Required to be passed
	addState: LoansAddState;
	adding: boolean;
	errors: ErrorsState;
	saveCallback: () => void;

	// Redux
	loansSetField: typeof loansSetField;
	loans: LoansState;
	bankAccounts: BankAccountsState;
	loansGetLoans: typeof loansGetLoans;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts
};

class LoanForm extends Component<Props> {

	componentWillMount() {
		this.props.loansGetLoans();
		this.props.bankAccountsGetAccounts();

		// @TODO this is only needed until we allow more than just monthly
		this.props.loansSetField( 'frequency', MONTHLY )
	}

	render() {
		let disabled = false,
			isLoading = '';
		if ( this.props.adding ) {
			disabled = true;
			isLoading = 'is-loading';
		}

		const {errors} = this.props;

		return (
			<form className="loans-form section" onSubmit={ event => { event.preventDefault(); this.props.saveCallback(); }}>
				<div className="columns">
					<div className="field column is-half">
						<label htmlFor="name" className="label">Name</label>
						<div className="control">
							<input type="text" id="name" className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder="Name" onChange={event => {this.props.loansSetField( 'name', event.target.value )}} value={this.props.addState.name} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
					</div>
					<div className="field column is-one-quarter">
						<label htmlFor="interest_rate" className="label">Interest Rate</label>
						<div className="control">
							<input type="text" id="interest_rate"
								   className={`input ${formFieldErrorClass(errors, 'interest_rate')}`}
								   placeholder="Interest Rate"
								   onChange={event => {this.props.loansSetField( 'interest_rate', event.target.value)}}
								   value={this.props.addState.interest_rate} />
						</div>
					</div>
					<div className="field column is-one-quarter">
						<label htmlFor="amount" className="label">Balance</label>
						<div className="control">
							<input type="text" id="amount" className={`input ${formFieldErrorClass(errors, 'amount')}`} placeholder="Balance" onChange={event => {this.props.loansSetField( 'amount', event.target.value )}} value={this.props.addState.amount} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'amount')}</p>
					</div>
				</div>

				<div className="columns">
					<div className="field column is-third">
						<label htmlFor="minimum_payment" className="label">Minimum Payment</label>
						<div className="control">
							<input type="text" id="minimum_payment" className={`input ${formFieldErrorClass(errors, 'minimum_payment')}`} placeholder="Minimum Payment" onChange={event => {this.props.loansSetField( 'minimum_payment', event.target.value )}} value={this.props.addState.minimum_payment} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'minimum_payment')}</p>
					</div>
					<div className="field column is-third">
						<label htmlFor="extra_payment" className="label">Extra Payment</label>
						<div className="control">
							<input type="text" id="extra_payment" className={`input ${formFieldErrorClass(errors, 'extra_payment')}`} placeholder="Extra Payment" onChange={event => {this.props.loansSetField( 'extra_payment', event.target.value )}} value={this.props.addState.extra_payment} />
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'extra_payment')}</p>
					</div>
					<div className="field column is-third">
						<label htmlFor="total_payment" className="label">Total Payment</label>
						<div className="control">
							<input type="text" disabled={true} className="input" value={parseFloat(this.props.addState.minimum_payment) + parseFloat(this.props.addState.extra_payment)}/>
						</div>
					</div>
				</div>

				<div className="columns">
					<div className="field column is-one-quarter">
						<label htmlFor="date" className="label">Date</label>
						<div className="control">
							<input type="text" id="date" className={`input ${formFieldErrorClass(errors, 'date')}`} placeholder="Date" onChange={event => {this.props.loansSetField( 'date', event.target.value)}} value={this.props.addState.date}/>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'date')}</p>
					</div>
					<BankAccountDropdown
						bankAccounts={this.props.bankAccounts}
						selected={this.props.addState.bank_account_id}
						selectCallback={(value) => {
							this.props.loansSetField( 'bank_account_id', value );
						}}
						extraClasses="column is-one-quarter"
						errors={errors}
					/>

					<div className="field column is-one-quarter">
						<label htmlFor="frequency" className="label">Frequency</label>
						<div className="control">
							<div className={`select is-fullwidth ${formFieldErrorClass(errors, 'frequency')}`}>
								{/* @todo Only supports monthly right now, because of payment calcs and such */}
								<select name="frequency" id="frequency" value={this.props.addState.frequency} onChange={event => {this.props.loansSetField( 'frequency', event.target.value )}}>
									<option value={MONTHLY}>{FREQUENCY_LABELS[MONTHLY].recurring}</option>
								</select>
							</div>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'frequency')}</p>
					</div>

					<div className="field column is-one-quarter">
						<div className="label">&nbsp;</div>
						<div className="control">
							<label className="checkbox">
								<input type="checkbox" checked={this.props.addState.autopay} onChange={ event => { this.props.loansSetField( 'autopay', event.target.checked )}} />
								&nbsp; Autopay
							</label>
						</div>
						<p className="help is-danger">{formErrorsForField(errors, 'autopay')}</p>
					</div>
				</div>

				<div className="field">
					<label htmlFor="notes" className="label">Notes</label>
					<div className="control">
						<textarea className={`textarea ${formFieldErrorClass(errors, 'notes')}`} name="notes" id="notes" rows="6" onChange={event => {this.props.loansSetField('notes', event.target.value)}} value={this.props.addState.notes}></textarea>
					</div>
					<p className="help is-danger">{formErrorsForField(errors, 'notes')}</p>
				</div>

				<div className={`field is-grouped`}>
					<div className={`control`}>
						<button disabled={disabled} className={`button is-primary ${isLoading}`} onClick={this.props.saveCallback}>
							Save
						</button>
					</div>
				</div>
			</form>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	loans: state.loans,
	bankAccounts: state.bankAccounts,
});

export const ConnectedLoanForm = connect( mapStateToProps, mapDispatchToProps )(LoanForm);
