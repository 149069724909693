// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { Link } from 'react-router-dom';

import {
	authLogin,
	authSetPassword,
	authSetUsername,
} from '../../actions/auth';
import type { AuthState, RegistrationState, State } from '../../types/state';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	auth: AuthState;
	authLogin: typeof authLogin;
	authSetUsername: typeof authSetUsername;
	authSetPassword: typeof authSetPassword;
	registration: RegistrationState;
};

class Login extends Component<Props> {

	handleChange = ( input, event ) => {
		switch ( input ) {
			case 'username':
				this.props.authSetUsername( event.target.value );
				break;
			case 'password':
				this.props.authSetPassword( event.target.value );
				break;
			default:

				break;
		}
	};

	handleLogin = () => {
		const { username, password } = this.props.auth.loginForm;

		this.props.authLogin( username, password );
	};

	render() {
		const { loginForm } = this.props.auth;
		let canSubmit = loginForm.username.length > 0 && loginForm.password.length > 0;


		// Button state for loading
		let isLoading = this.props.auth.loading ? 'is-loading' : '';

		return (
			<ConnectedSectionWrapper
				title="Login"
				render={() => (
					<div className="container">
						<div className="content has-text-centered">
							Don't have an account? <Link to="/register">Register Here</Link>
						</div>

						<form className="login-form section" onSubmit={ event => { event.preventDefault(); this.handleLogin() }}>
							{this.props.registration.success === true && (
								<article className="message is-success">
									<div className="message-body">
										Registration Successful! Log in below.
									</div>
								</article>
							)}

							{true === this.props.auth.loginError && (
								<div className="notification is-danger">
									You have entered an invalid username or password
								</div>
							)}

							<div className={`field`}>
								<label htmlFor="username" className="label">Username</label>
								<div className="control has-icons-left">
									<input id={`username`} className={`input`} placeholder={`Username`} type="text" value={loginForm.username} onChange={event => {this.handleChange( 'username', event )}}/>
									<span className={`icon is-small is-left`}>
									<	FontAwesomeIcon icon="user" />
									</span>
								</div>
							</div>

							<div className={`field`}>
								<label htmlFor="password" className="label">Password</label>
								<div className="control has-icons-left">
									<input id={`password`} className={`input`} placeholder={`Password`} type="password" value={loginForm.password} onChange={event => {this.handleChange( 'password', event )}}/>
									<span className={`icon is-small is-left`}>
										<FontAwesomeIcon icon={`lock`} />
									</span>
								</div>
							</div>

							<div className="field">
								<button disabled={!canSubmit} className={`button is-info ${isLoading}`} onClick={this.handleLogin}>Login</button>
							</div>

						</form>
					</div>
				)}
			/>
		)
	}
}

const mapStateToProps = ( state: State ) => ( {
	auth: state.auth,
	registration: state.registration,
} );

export const ConnectedLogin = connect( mapStateToProps, mapDispatchToProps )( Login );
