import { RSAA } from 'redux-api-middleware';

export const urlHelper = ( path ) => {
	let apiurl = process.env.REACT_APP_API_URL || '';
	let basepath = process.env.REACT_APP_API_BASE || '';

	if ( 0 !== path.indexOf( 'http' ) ) {
		// Doesn't start with http(s)://, so we treat it as a path

		// Remove any leading & trailing slashes from apiurl and basepath, so we don't duplicate them in the final url
		let url = apiurl.replace( /\/$/, '' ).replace( /^\//, '' );
		let base = basepath.replace( /\/$/, '' ).replace( /^\//, '' );

		// Remove leading slash from path, so that we don't duplicate
		path = path.replace( /^\//, '' );

		return `${url}/${base}/${path}`;
	}

	return path;
};

export const get = ( endpoint, types, meta ) => {
	return request( endpoint, types, null, 'GET', meta );
};

export const post = ( endpoint, types, body, meta ) => {
	return request( endpoint, types, body, 'POST', meta );
};

export const put = ( endpoint, types, body, meta ) => {
	return request( endpoint, types, body, 'PUT', meta );
};

export const del = ( endpoint, types, body, meta ) => {
	return request( endpoint, types, body, 'DELETE', meta );
};

export const request = ( endpoint, types, body, method, extraMeta ) => {
	let action = {
		endpoint: urlHelper( endpoint ),
		method: method,
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		types: [
			{
				type: types[0],
				meta: { ...extraMeta }
			},
			{
				type: types[1],
				// Add response headers to the action meta
				meta: ( action,state,res ) => {
					let meta = { ...extraMeta, headers: {} };

					res.headers.forEach( ( val, key ) => {
						meta.headers[ key ] = val;
					} );

					return meta;
				}
			},
			{
				type: types[2],
				meta: { ...extraMeta }
			}
		],
	};

	if ( body && 'GET' !== method ) {
		action.body = JSON.stringify( body );
	}

	return {
		[RSAA]: action
	};
};
