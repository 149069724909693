// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { ConnectedMainNavigation } from '../mainNavigation';
import type { State } from '../../types/state';

export class SiteHeader extends Component<void> {
	render() {
		return (
			<header className="app-header budget-site-header">
				<ConnectedMainNavigation/>
			</header>
		)
	}
}

const mapStateToProps = ( state: State ) => ( {

} );

export const ConnectedSiteHeader = connect( mapStateToProps, mapDispatchToProps )( SiteHeader );
