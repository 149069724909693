// @flow
import * as React from 'react'

export const formatUSD = ( amount: number ) => {
	return new Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD' }).format( amount );
};

export const formatPercent = ( percent: number ) => {
	return new Intl.NumberFormat( 'en-US', { style: 'percent', maximumFractionDigits: 4 }).format(percent/100);
};

export const stripNonNumberForSubmit = ( amount: string|number ) => {
	if ( "string" === typeof amount ) {
		return amount.replace(/[^0-9.]/g, "");
	}

	return amount;
};

export const nl2br = (str: string) => {
	return str.split('\n').map<any>((item: string, key: number) : React.Node => {
		return <React.Fragment key={key}>{item}<br/></React.Fragment>
	})
};
