import createReducer from '../utils/createReducer';
import {
	INCOME_ADD_INCOME_BEGIN,
	INCOME_ADD_INCOME_FAILURE,
	INCOME_ADD_INCOME_SUCCESS,
	INCOME_DELETE_INCOME_SUCCESS, INCOME_EDIT_INCOME_BEGIN, INCOME_EDIT_INCOME_FAILURE, INCOME_EDIT_INCOME_SUCCESS,
	INCOME_EDIT_SET_STATE,
	INCOME_GET_INCOME_SUCCESS,
	INCOME_SET_ADD_FIELD,
} from '../actions/income';
import {
	addEditBeginState,
	addEditFailedState, addEditSuccessState,
	deleteSingleObjectFromState,
	objectDataToState, setEditStateHelper
} from '../utils/stateHelpers';
import { defaultObjectState } from './common/objectState';
import dotProp from 'dot-prop-immutable';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';

let defaultIncomeState = {
	...defaultObjectState,
	add: {
		'name': '',
		'amount': 0.0000,
		'gross_income': 0.0000,
		'frequency': '',
		'bank_account_id': 0,
		'notes': '',
	}
};

export const income = createReducer( defaultIncomeState, {
	[INCOME_GET_INCOME_SUCCESS]( state, action ) {
		return objectDataToState( state, action );
	},
	[INCOME_SET_ADD_FIELD]( state, action ) {
		return dotProp.set( state, `add.${action.payload.field}`, action.payload.value );
	},
	[INCOME_ADD_INCOME_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[INCOME_ADD_INCOME_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultIncomeState );
	},
	[INCOME_ADD_INCOME_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[INCOME_EDIT_SET_STATE]( state, action ) {
		return setEditStateHelper( state, action, defaultIncomeState );
	},
	[INCOME_EDIT_INCOME_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[INCOME_EDIT_INCOME_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultIncomeState );
	},
	[INCOME_EDIT_INCOME_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[INCOME_DELETE_INCOME_SUCCESS]( state, action ) {
		return deleteSingleObjectFromState( state, action.meta.id );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultIncomeState;
	}
});
