// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { ExpensesAddState, ExpensesState, State } from '../../types/state';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';

import {
	expensesEditExpense,
	expensesGetExpenses,
	expensesSetStateForEdit
} from '../../actions/expenses';
import { ConnectedExpenseForm } from './form';
import { organizeExpenses } from '../../utils/objectHelpers';
import { returnSubsetByIdOrParent } from '../../utils/stateHelpers';
import type { Match } from 'react-router-dom';

type Props = {
	expensesGetExpenses: typeof expensesGetExpenses;
	expensesSetStateForEdit: typeof expensesSetStateForEdit;
	expensesEditExpense: typeof expensesEditExpense;
	addState: ExpensesAddState;
	adding: boolean;
	errors: string[];
	match: Match;
	expenses: ExpensesState;
};

class EditExpense extends Component<Props> {

	componentWillMount() {
		this.initialLoad();
	}

	componentDidUpdate() {
		this.initialLoad();
	}

	initialLoad = () => {
		this.props.expensesGetExpenses();

		this.setEditState();
	};

	setEditState = () => {
		if ( this.props.expenses.ids.indexOf( this.getId() ) === -1 ) {
			return false;
		}

		if ( this.props.expenses.editing === this.getId() ) {
			return false;
		}

		let expenseState = organizeExpenses( returnSubsetByIdOrParent( this.getId(), this.props.expenses.objects ) );

		expenseState = expenseState[0];

		this.props.expensesSetStateForEdit( this.getId(), expenseState );
	};

	getId = () : number => {
		let { id } = this.props.match.params;

		id = 'string' === typeof id ? id : '';

		// get rid of bad characters
		id = id.replace( '\ufeff', '' );

		return parseInt( id, 10 );
	};

	save = () => {
		this.props.expensesEditExpense( this.getId() );
	};

	render() {
		return (
			<ConnectedSectionWrapper
				title="Edit Expense"
				render={() => (
					<ConnectedExpenseForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.save}
					/>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.expenses.add,
	adding: state.expenses.adding,
	errors: state.expenses.errors,
	expenses: state.expenses,
});

export const ConnectedEditExpense = connect( mapStateToProps, mapDispatchToProps )( EditExpense );
