// @flow

import React, { Component } from 'react';
import { formatUSD } from '../../utils/formattingHelpers';

type Props = {
	totalIncome: number;
};

export class TotalGrossIncomeLevel extends Component<Props> {

	render() {
		const { totalIncome } = this.props;

		return (
			<div className="level-item has-text-centered">
				<div>
					<p className="title has-text-primary-dark is-size-4-touch">{ formatUSD( totalIncome ) }</p>
					<p className="heading">Total Gross Income</p>
				</div>
			</div>
		)
	}

}
