// @flow

import { Dispatch } from 'redux';
import type { Action, GetState } from '../types/actions';
import { get, post, put, del } from '../utils/rsaaHelpers';
import history from '../history';
import { stripNonNumberForSubmit } from '../utils/formattingHelpers';

export const EXPENSES_GET_EXPENSES_BEGIN = 'EXPENSES_GET_EXPENSES_BEGIN';
export const EXPENSES_GET_EXPENSES_SUCCESS = 'EXPENSES_GET_EXPENSES_SUCCESS';
export const EXPENSES_GET_EXPENSES_FAILURE = 'EXPENSES_GET_EXPENSES_FAILURE';

export const EXPENSES_ADD_EXPENSE_BEGIN = 'EXPENSES_ADD_EXPENSE_BEGIN';
export const EXPENSES_ADD_EXPENSE_SUCCESS = 'EXPENSES_ADD_EXPENSE_SUCCESS';
export const EXPENSES_ADD_EXPENSE_FAILURE = 'EXPENSES_ADD_EXPENSE_FAILURE';

export const EXPENSES_EDIT_SET_STATE = 'EXPENSES_EDIT_SET_STATE';

export const EXPENSES_EDIT_EXPENSE_BEGIN = 'EXPENSES_EDIT_EXPENSE_BEGIN';
export const EXPENSES_EDIT_EXPENSE_SUCCESS = 'EXPENSES_EDIT_EXPENSE_SUCCESS';
export const EXPENSES_EDIT_EXPENSE_FAILURE = 'EXPENSES_EDIT_EXPENSE_FAILURE';

export const EXPENSES_DELETE_EXPENSE_BEGIN = 'EXPENSES_DELETE_EXPENSE_BEGIN';
export const EXPENSES_DELETE_EXPENSE_SUCCESS = 'EXPENSES_DELETE_EXPENSE_SUCCESS';
export const EXPENSES_DELETE_EXPENSE_FAILURE = 'EXPENSES_DELETE_EXPENSE_FAILURE';

// Tracks fields as they are entered
export const EXPENSES_SET_ADD_FIELD = 'EXPENSES_SET_ADD_FIELD';

// Adds a new empty split
export const EXPENSES_ADD_SPLIT = 'EXPENSES_ADD_SPLIT';
export const EXPENSES_DELETE_SPLIT = 'EXPENSES_DELETE_SPLIT';

export const expensesGetExpenses = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( get(
		`/accounts/${activeAccount}/expenses`,
		[ EXPENSES_GET_EXPENSES_BEGIN, EXPENSES_GET_EXPENSES_SUCCESS, EXPENSES_GET_EXPENSES_FAILURE ]
	) );
};

export const expensesAddExpense = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().expenses;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( post(
		`/accounts/${activeAccount}/expenses`,
		[ EXPENSES_ADD_EXPENSE_BEGIN, EXPENSES_ADD_EXPENSE_SUCCESS, EXPENSES_ADD_EXPENSE_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
			amount: stripNonNumberForSubmit( add.amount ),
		}
	) ).then( () => {
		const { errors } = getState().expenses;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/expenses' );
		}
	})
};

export const expensesSetStateForEdit = ( id: number, state: {} ) : Action => {
	return {
		type: EXPENSES_EDIT_SET_STATE,
		payload: {
			id,
			state
		}
	}
};

export const expensesEditExpense = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().expenses;
	const { activeAccount } = getState().accounts;
	const { activeBudget } = getState().budgets;

	dispatch( put(
		`/accounts/${activeAccount}/expenses/${id}`,
		[ EXPENSES_EDIT_EXPENSE_BEGIN, EXPENSES_EDIT_EXPENSE_SUCCESS, EXPENSES_EDIT_EXPENSE_FAILURE ],
		{
			...add,
			budget_id: activeBudget,
			amount: stripNonNumberForSubmit( add.amount ),
		}
	) ).then( () => {
		const { errors } = getState().expenses;

		if ( Object.keys( errors ).length === 0 ) {
			history.push( '/expenses' );
		}
	})
};

export const expensesDeleteExpense = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( del(
		`/accounts/${activeAccount}/expenses/${id}`,
		[ EXPENSES_DELETE_EXPENSE_BEGIN, EXPENSES_DELETE_EXPENSE_SUCCESS, EXPENSES_DELETE_EXPENSE_FAILURE ],
		{},
		{id}
	) );
};

export const expensesSetField = ( field: string, value: any ) => {
	return {
		type: EXPENSES_SET_ADD_FIELD,
		payload: {
			field,
			value
		}
	}
};

export const expensesAddSplit = () => {
	return {
		type: EXPENSES_ADD_SPLIT
	}
};

export const expensesDeleteSplit = ( index: number ) => {
	return {
		type: EXPENSES_DELETE_SPLIT,
		payload: {
			index
		}
	}
};
