// @flow

import React, { Component } from 'react';
import type { BankAccountsState, ErrorsState } from '../../types/state';
import { objectToArray, sortObjectsByName } from '../../utils/objectHelpers';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	bankAccounts: BankAccountsState;
	selected: number;
	selectCallback: ( string ) => any;
	extraClasses: ?string;
	errors: ErrorsState;
};

export class BankAccountDropdown extends Component<Props> {

	render() {
		let bankAccounts = sortObjectsByName( objectToArray( this.props.bankAccounts.objects ) );

		return (
			<div className={`field ${this.props.extraClasses || ''}`}>
				<label htmlFor="bank_account_id" className="label">Bank Account</label>
				<div className="control">
					<div className={`select is-fullwidth ${formFieldErrorClass(this.props.errors, 'bank_account_id')}`}>
						<select name="bank_account_id" id="bank_account_id" value={this.props.selected} onChange={event => { this.props.selectCallback( event.target.value )}} >
							<option value="0">&mdash; Select Bank Account &mdash;</option>
							{bankAccounts.map( ( bankAccount ) => {
								return (
									<option key={bankAccount.id} value={bankAccount.id}>{bankAccount.name}</option>
								)
							})}
						</select>
					</div>
				</div>
				<p className="help is-danger">{formErrorsForField(this.props.errors, 'bank_account_id')}</p>
				<p className="help">Optional link to bank account.</p>
			</div>
		)
	}

}
