// @flow

export const activePeriodLocalStorageKey = 'budgetActivePeriod';

export const setLocalStorageActivePeriod = ( period: string ) => {
	if ( window && window.localStorage ) {
		window.localStorage.setItem( activePeriodLocalStorageKey, period );
	}
};

export const getLocalStorageActivePeriod = () : string => {
	let activePeriod = '';

	if ( window && window.localStorage ) {
		activePeriod = window.localStorage.getItem( activePeriodLocalStorageKey );
	}

	return activePeriod;
};

export const clearLocalStorageActivePeriod = () => {
	if ( window && window.localStorage ) {
		window.localStorage.removeItem( activePeriodLocalStorageKey );
	}
};
