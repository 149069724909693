import { isRSAA, RSAA } from 'redux-api-middleware';
import { authIsExpired } from '../actions/auth';

const activeRequests = {};

/**
 * Returns a promise for anything that tries to call a duplicate request while another is already running
 *
 * @param store
 * @returns {function(*): Function}
 */
export const apiDupePromise = store => next => action => {
	if ( ! isRSAA( action ) ) {
		return next( action );
	}

	// Don't make duplicate calls
	let endpoint = action[ RSAA ].endpoint;
	if ( activeRequests[ endpoint ] ) {
		return activeRequests[ endpoint ];
	}

	const { auth } = store.getState();
	if ( auth.accessToken && auth.accessToken !== '' ) {
		let expiration = auth.accessTokenExpiration;

		// if token is expired, trigger the expired action
		if ( expiration < Date.now() ) {
			store.dispatch( authIsExpired() );
		} else {
			action[ RSAA ].headers.authorization = `Bearer ${auth.accessToken}`;
		}
	}

	// Track the request is being made so we can avoid duplicates
	// Tracking the promise so that in case a duplicate, we can just return that promise
	activeRequests[ endpoint ] = next( action ).then( () => delete activeRequests[ endpoint ] );

	return activeRequests[ endpoint ];
};

export default apiDupePromise
