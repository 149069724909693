// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { RegistrationState, State } from '../../types/state';
import { register } from '../../actions/registration';
import type { RegistrationComponentState } from '../../types/registration';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { Link } from 'react-router-dom';
import { formErrorsForField, formFieldErrorClass } from '../../utils/formErrors';

type Props = {
	registration: RegistrationState;
	register: typeof register;
};

class Register extends Component<Props, RegistrationComponentState> {

	state = {
		name: '',
		username: '',
		email: '',
		password: '',
		password_confirmation: '',
		//captchaVerified: false,
	};

	handleChange = ( input, event ) => {
		this.setState({
			[input]: event.target.value,
		})
	};

	register = () => {
		this.props.register( this.state );
	};

	render() {
		let passwordMatch = false;

		let passwordMatchClass = '';
		if ( this.state.password && this.state.password_confirmation ) {
			passwordMatch = this.state.password === this.state.password_confirmation;
			passwordMatchClass = passwordMatch ? 'is-success' : 'is-danger';
		}

		let canSubmit = ( passwordMatch && this.state.name && this.state.username && this.state.email );

		// Button state for loading
		let isLoading = this.props.registration.loading ? 'is-loading' : '';

		const { errors } = this.props.registration;

		return (
			<ConnectedSectionWrapper
				title="Register"
				render={() => (
					<div className="container">
						<div className="content has-text-centered">
							Already have an account? <Link to="/login">Log In Here</Link>
						</div>

						<form className="register-form section" onSubmit={ e => { e.preventDefault(); this.register(); }}>
							<div className="field">
								<label htmlFor="name" className="label">Name</label>
								<div className="control">
									<input id={`name`} className={`input ${formFieldErrorClass(errors, 'name')}`} placeholder={`Name`} type="text" value={this.state.name} onChange={event => {this.handleChange( 'name', event )}}/>
								</div>
								<p className="help is-danger">{formErrorsForField(errors, 'name')}</p>
							</div>

							<div className={`field`}>
								<label htmlFor="username" className="label">Username</label>
								<div className="control has-icons-left">
									<input id={`username`} className={`input ${formFieldErrorClass(errors,'username')}`} placeholder={`Username`} type="text" value={this.state.username} onChange={event => {this.handleChange( 'username', event )}}/>
									<span className={`icon is-small is-left`}>
										<FontAwesomeIcon icon={`user`} />
									</span>
								</div>
								<p className="help is-danger">{formErrorsForField(errors, 'username')}</p>
							</div>

							<div className={`field`}>
								<label htmlFor="email" className="label">Email</label>
								<div className="control has-icons-left">
									<input id={`email`} className={`input ${formFieldErrorClass(errors,'email')}`} placeholder={`Email Address`} type="text" value={this.state.email} onChange={event => {this.handleChange( 'email', event )}}/>
									<span className={`icon is-small is-left`}>
										<FontAwesomeIcon icon={`envelope`} />
									</span>
								</div>
								<p className="help is-danger">{formErrorsForField(errors,'email')}</p>
							</div>

							<div className="columns">
								<div className={`field column is-half`}>
									<label htmlFor="password" className="label">Password</label>
									<div className="control has-icons-left">
										<input id={`password`} className={`input ${formFieldErrorClass(errors, 'password')}`} placeholder={`Password`} type="password" value={this.state.password} onChange={event => {this.handleChange( 'password', event )}}/>
										<span className={`icon is-small is-left`}>
											<FontAwesomeIcon icon={`lock`} />
										</span>
									</div>
									<p className="help is-danger">{formErrorsForField(errors, 'password')}</p>
								</div>

								<div className={`field column is-half`}>
									<label htmlFor="password_confirmation" className="label">Confirm Password</label>
									<div className="control has-icons-left">
										<input id={`password_confirmation`} className={`input ${passwordMatchClass} ${formFieldErrorClass(errors, 'password_confirmation')}`} placeholder={`Confirm Password`} type="password" value={this.state.password_confirmation} onChange={event => {this.handleChange( 'password_confirmation', event )}}/>
										<span className={`icon is-small is-left`}>
											<FontAwesomeIcon icon={`lock`} />
										</span>
									</div>
									<p className="help is-danger">{formErrorsForField(errors,'password_confirmation')}</p>
								</div>
							</div>

							<div className="field">
								<button disabled={!canSubmit} className={`button is-primary ${isLoading}`} onClick={this.register}>Register</button>
							</div>
						</form>
					</div>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state : State ) => ( {
	registration: state.registration,
} );

export const ConnectedRegister = connect( mapStateToProps, mapDispatchToProps )( Register );
