// @flow

import dotProp from 'dot-prop-immutable';

import type { State } from '../types/state';
import type { Action } from '../types/actions';
import type { objectBase } from '../types/objects';

/**
 * Returns a subset of the provided data based on the provided array of IDs
 *
 * ids expected in the form of [ 1, 3, 4, 7, 8 ]
 * data expected in the form:
 *   {
 *     1: value1,
 *     2: value2,
 *     3: value3,
 *     4: value4,
 *     5: value5,
 *     6: value6,
 *   }
 *
 * returnSubsetById( [ 1, 3 ], data ); // Using data above returns:
 *   [ value1, value3 ]
 *
 * @param {array} ids Array of IDs to return from the provided data
 * @param {object} data Object, expected in the form of { id: <value>, id: <value> }
 *
 * @return array Returns an array of the data that matches the IDs provided
 */
export const returnSubsetById = ( ids: string[], data: {} ) => {
	let finalData = [];

	ids.forEach( ( id ) => {
		let currentItem = dotProp.get( data, id );
		currentItem && finalData.push( currentItem );
	} );

	return finalData;
};

export const returnSubsetByIdOrParent = ( matchId: string|number, data: {} ) => {
	let finalData = [];

	matchId = parseInt( matchId, 10 );

	Object.keys( data ).forEach( currentId => {
		let currentItem = dotProp.get( data, currentId );

		if ( parseInt( currentItem.id, 10 ) === matchId || parseInt( currentItem.parent, 10 ) === matchId ) {
			finalData.push( currentItem );
		}
	});

	return finalData;
};

/**
 * Helper for immutable Array.push like functionality
 *
 * @param state
 * @param path
 * @param value
 */
export const arrayAdd = ( state: State, path: string, value: mixed ) => {
	let array = dotProp.get( state, path ) || [];

	if ( ! array.includes( value ) ) {
		array = array.concat( value );
	}

	return dotProp.set( state, path, array );
};

export const objectDataToState = ( state: State, action: Action ) => {
	action.payload.data.forEach( object => {
		state = objectDataToStateSingle( state, object );
	} );

	return state;
};

// adds slug+id only
export const objectDataToStateSingle = ( state: State, object: objectBase ) => {
	// Generally in the case of a duplicate, the api response is different
	if ( undefined === object.id ) {
		return state;
	}
	state = dotProp.set( state, `objects.${object.id}`, object );
	state = arrayAdd( state, 'ids', object.id );

	return state;
};

export const deleteSingleObjectFromState = ( state: State, id: number ) => {
	state = dotProp.delete( state, `objects.${id}` );
	state = dotProp.set( state, 'ids', dotProp.get( state, 'ids' ).filter( item => item !== id ));

	return state;
};

/**
 * Sets up state when starting an API call for create/edit of an object
 * @param state
 * @returns {{}}
 */
export const addEditBeginState = ( state: {} ) : {} => {
	state = dotProp.set( state, 'adding', true );
	state = dotProp.set( state, 'errors', {} );

	return state;
};

export const addEditFailedState = ( state: {}, action: Action ) : {} => {
	state = dotProp.set( state, 'adding', false );

	if ( action.payload && action.payload.status === 400 && action.payload.response ) {
		for ( let field in action.payload.response ) {
			if ( dotProp.get( state, `errors.${field}`, false ) === false ) {
				state = dotProp.set( state, `errors.${field}`, [] );
			}

			state = arrayAdd( state, `errors.${field}`, action.payload.response[ field ] );
		}
	} else {
		if ( action.payload.response && Array.isArray( action.payload.response ) ) {
			state = arrayAdd( state, 'errors.generic', action.payload.response );
		} else {
			state = arrayAdd( state, 'errors.generic', "An unknown error occurred" );
		}
	}

	return state;
};

export const addEditSuccessState = ( state: {}, action: Action, defaultState: { add: {} } ) : {} => {
	state = dotProp.set( state, 'add', defaultState.add );
	state = dotProp.set( state, 'adding', false );
	state = dotProp.set( state, 'errors', {} );
	state = dotProp.set( state, 'editing', 0 );
	state = objectDataToStateSingle( state, action.payload );

	return state;
};

export const setEditStateHelper = ( state: {}, action: Action, defaultState: { add: {} } ) : {} => {
	const object = action.payload.state;
	const defaultAdd = defaultState.add;

	// First reset to default state
	state = dotProp.set( state, 'add', defaultAdd );
	state = dotProp.set( state, 'errors', {} );

	if ( undefined !== action.payload.id ) {
		state = dotProp.set( state, 'editing', action.payload.id );
	}

	if ( ! object ) {
		return state;
	}

	Object.keys( defaultAdd ).forEach( key => {
		if ( object.hasOwnProperty( key ) ) {
			if ( 'children' === key ) {
				object[ key ].forEach( child => {
					state = arrayAdd( state, 'add.children', child )
				});
			} else {
				state = dotProp.set( state, `add.${key}`, object[ key ] );
			}
		}
	});

	return state;
};
