import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	ACCOUNTS_ADD_ACCOUNT_BEGIN,
	ACCOUNTS_ADD_ACCOUNT_FAILURE,
	ACCOUNTS_ADD_ACCOUNT_SUCCESS,
	ACCOUNTS_CLONE_ACCOUNT_BEGIN,
	ACCOUNTS_CLONE_ACCOUNT_FAILURE,
	ACCOUNTS_CLONE_ACCOUNT_SUCCESS,
	ACCOUNTS_DELETE_ACCOUNT_BEGIN,
	ACCOUNTS_DELETE_ACCOUNT_FAILURE,
	ACCOUNTS_DELETE_ACCOUNT_SUCCESS,
	ACCOUNTS_DELETE_INVITE_SUCCESS,
	ACCOUNTS_GET_ACCOUNTS_SUCCESS,
	ACCOUNTS_GET_INVITES_SUCCESS,
	ACCOUNTS_SET_ACTIVE_ACCOUNT,
	ACCOUNTS_SET_ADD_FIELD,
	ACCOUNTS_SWITCH_ACCOUNT,
} from '../actions/accounts';
import {
	addEditBeginState,
	addEditFailedState, addEditSuccessState, deleteSingleObjectFromState,
	objectDataToState,
} from '../utils/stateHelpers';
import { clearLocalStorageActiveAccount, setLocalStorageActiveAccount } from '../utils/accountUtils';

export const defaultAccountsState = {
	objects: {},
	ids: [],
	activeAccount: 0,
	loaded: false,
	errors: {},
	adding: false,
	cloning: false,
	add: {
		name: '',
	},
	invites: {
		objects: {},
		ids: [],
	}
};

export const accounts = createReducer( defaultAccountsState, {
	[ACCOUNTS_GET_ACCOUNTS_SUCCESS]( state, action ) {
		// Reset accounts first
		state = dotProp.set(state, 'objects', {})
		state = dotProp.set(state, 'ids', [])

		state = objectDataToState( state, action );
		state = dotProp.set( state, 'loaded', true );

		if ( state.ids.length === 0 ) {
			state = dotProp.set( state, 'activeAccount', 0 );
			clearLocalStorageActiveAccount();
		}

		return state;
	},
	[ACCOUNTS_SET_ADD_FIELD]( state, action ) {
		return dotProp.set( state, `add.${action.payload.field}`, action.payload.value );
	},
	[ACCOUNTS_ADD_ACCOUNT_BEGIN](state, action ) {
		return addEditBeginState( state );
	},
	[ACCOUNTS_ADD_ACCOUNT_SUCCESS](state, action ) {
		return addEditSuccessState( state, action, defaultAccountsState );
	},
	[ACCOUNTS_ADD_ACCOUNT_FAILURE](state, action ) {
		return addEditFailedState( state, action );
	},
	[ACCOUNTS_CLONE_ACCOUNT_BEGIN](state, action) {
		state = dotProp.set( state, 'cloning', true );
		state = dotProp.set( state, 'errors', {} );

		return state;
	},
	[ACCOUNTS_CLONE_ACCOUNT_SUCCESS](state, action) {
		state = dotProp.set( state, 'cloning', false );
		state = dotProp.set( state, 'errors', {} );

		return state;
	},
	[ACCOUNTS_CLONE_ACCOUNT_FAILURE](state, action) {
		state = dotProp.set( state, 'cloning', false );
		state = addEditFailedState(state, action)

		return state;
	},
	[ACCOUNTS_DELETE_ACCOUNT_BEGIN](state, action) {
		return state
	},
	[ACCOUNTS_DELETE_ACCOUNT_SUCCESS](state, action) {
		return state
	},
	[ACCOUNTS_DELETE_ACCOUNT_FAILURE](state, action) {
		return state
	},
	[ACCOUNTS_SET_ACTIVE_ACCOUNT]( state, action ) {
		state = dotProp.set( state, 'activeAccount', action.payload.id );

		setLocalStorageActiveAccount( action.payload.id );

		return state;
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		state = dotProp.set( state, 'loaded', false );

		return state;
	},
	[ACCOUNTS_GET_INVITES_SUCCESS]( state, action ) {
		state = dotProp.set( state, 'invites', objectDataToState( state.invites, action ) );

		return state;
	},
	[ACCOUNTS_DELETE_INVITE_SUCCESS]( state, action ) {
		return dotProp.set( state, 'invites', deleteSingleObjectFromState( state.invites, action.meta.id ) );
	}
});
