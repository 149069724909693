import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {APP_CLOSE_MENU, APP_OPEN_MENU, APP_SET_PERIOD} from '../actions/app';
import { MONTHLY } from '../utils/frequencyHelpers';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';
import { setLocalStorageActivePeriod } from '../utils/appUtils';

export const defaultAppState = {
	period: MONTHLY,
	menuOpen: false,
};

export const app = createReducer( defaultAppState, {
	[APP_SET_PERIOD]( state, action ) {
		setLocalStorageActivePeriod( action.payload.period );
		return dotProp.set( state, 'period', action.payload.period );
	},
	[APP_OPEN_MENU]( state, action ) {
		return dotProp.set( state, 'menuOpen', true );
	},
	[APP_CLOSE_MENU]( state, action ) {
		return dotProp.set( state, 'menuOpen', false );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return dotProp.set( state, 'menuOpen', false );
	},
});
