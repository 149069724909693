import history from '../history';

export function getQueryVariable( variable ) {
	return getQueryVariableFromHistory( history, variable );
}

function getQueryVariableFromHistory( history, variable ) {
	const query = history.location.search.substring( 1 );
	const vars = query.split( '&' );
	for ( let i = 0; i < vars.length; i++ ) {
		const pair = vars[i].split( '=' );
		if ( decodeURIComponent( pair[0] ) === variable ) {
			return decodeURIComponent( pair[1] );
		}
	}

	return false;
}
