// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { AccountsState, State } from '../../types/state';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { Link } from 'react-router-dom';
import { ConnectedUsersList } from "../users/list";
import { ConnectedAccountInvites } from "./invites";
import { getQueryVariable } from '../../utils/urlUtils';
import {DeleteConfirm} from "../shared/buttons/deleteConfirm"
import { accountsDeleteAccount, accountsSwitchAccount } from '../../actions/accounts';

type Props = {
	accounts: AccountsState;
	accountsDeleteAccount: typeof accountsDeleteAccount;
	accountsSwitchAccount: typeof accountsSwitchAccount;
};

type componentState = {
	activeTab: string;
};

export class ManageAccount extends Component<Props, componentState> {

	state = {
		activeTab: "users",
	};

	deleteAccount = (accountID: number) => {
		this.props.accountsDeleteAccount(accountID).then(() => {
			if ( Object.keys( this.props.accounts.errors ).length === 0 ) {
				this.props.accountsSwitchAccount()
			}
		});
	};

	tabActiveClass = ( tab : string ) : string => {
		return tab === this.state.activeTab ? 'is-active' : '';
	};

	activateTab = ( tab : string ) : void => {
		this.setState({activeTab: tab});
	};

	users = () => {
		if ( this.state.activeTab !== 'users' ) {
			return;
		}

		return (
			<>
				<div className="level">
					<Link to={`/users/invite`} className={`button is-info`}>Invite User</Link>
				</div>
				<ConnectedUsersList />
			</>
		);

	};

	invites = () => {
		if ( this.state.activeTab !== 'pending-invites' ) {
			return;
		}

		return (
			<>
				<ConnectedAccountInvites />
			</>
		);
	};

	render() {
		let account = this.props.accounts.objects[ this.props.accounts.activeAccount ];

		return (
			<ConnectedSectionWrapper
				title={"Manage Account"}
				subtitle={account && account.name ? account.name : '' }
				render={() => (
					<>
						{getQueryVariable( 'invited' ) !== false && (
							<div className="message is-success">
								<div className="message-body">User successfully invited!</div>
							</div>
						)}

						<div className="buttons is-justify-content-center">
							<Link to={`/accounts/clone`} className={`button is-small`}>Clone Account</Link>
							<DeleteConfirm buttonText="Delete Account" deleteMethod={() => { this.deleteAccount(this.props.accounts.activeAccount) }} />
						</div>

						<div className="tabs is-boxed">
							<ul>
								<li className={this.tabActiveClass('users')} onClick={() => this.activateTab('users')}><a>Users</a></li>
								<li className={this.tabActiveClass('pending-invites')} onClick={() => this.activateTab('pending-invites')}><a>Pending Invites</a></li>
							</ul>
						</div>
						<div>
							{this.users()}
							{this.invites()}
						</div>

					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ( {
	accounts: state.accounts,
} );

export const ConnectedManageAccount = connect( mapStateToProps, mapDispatchToProps )( ManageAccount );

