// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {BankAccountsState, LoansState, State} from '../../types/state';
import { returnSubsetById } from '../../utils/stateHelpers';
import { loansDeleteLoan, loansGetLoans } from '../../actions/loans';
import {formatPercent, formatUSD, nl2br} from '../../utils/formattingHelpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteConfirm } from '../shared/buttons/deleteConfirm';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { sortObjectsByName } from '../../utils/objectHelpers';
import {getPeriodEquivalent, getPeriodSum} from "../../utils/frequencyHelpers"
import {TotalLoansLevel} from "./totalLoansLevel"
import {bankAccountsGetAccounts} from "../../actions/bankAccounts"
import {CalculateNumberOfPaymentsLeft, CalculateSavedInterestFromExtraPayments} from "../../utils/loanUtils"
import type {LoanSingleObject} from "../../types/objects"

type Props = {
	loans: LoansState;
	bankAccounts: BankAccountsState;
	period: string;
	loansGetLoans: typeof loansGetLoans;
	loansDeleteLoan: typeof loansDeleteLoan;
	bankAccountsGetAccounts: typeof bankAccountsGetAccounts;
};

type ComponentState = {
	expandedLoan: number;
};

class Loans extends Component<Props, ComponentState> {

	state = {
		expandedLoan: 0,
	};

	componentWillMount() {
		this.props.loansGetLoans();
		this.props.bankAccountsGetAccounts();
	}

	getBankAccountName = ( accountID: number ) : string => {
		const { objects } = this.props.bankAccounts;

		if ( objects[ accountID ] ) {
			return objects[ accountID ].name;
		}

		return '';
	};

	getTotalLoanBalance() {
		let loans = returnSubsetById( this.props.loans.ids, this.props.loans.objects );
		let total = 0.000;

		loans.forEach( function( loan ) {
			total += parseFloat( loan.amount );
		});

		return total;
	}

	handleToggle = ( id: number ) => {
		if ( this.state.expandedLoan === id ) {
			this.setState({ expandedLoan: 0 });
		} else {
			this.setState({ expandedLoan: id });
		}
	};

	render() {
		let loans = sortObjectsByName( returnSubsetById( this.props.loans.ids, this.props.loans.objects ) ),
			minimumObjs = [],
			extraObjs = [],
			totalObjs = [];

		// Need to do this now so we can have it for the header
		loans.map( loan => {
			const totalObj = {
				amount: parseFloat(loan.extra_payment) + parseFloat(loan.minimum_payment),
				frequency: loan.frequency,
			};

			const minimumObj = {
				amount: parseFloat(loan.minimum_payment),
				frequency: loan.frequency,
			};

			const extraObj = {
				amount: parseFloat(loan.extra_payment),
				frequency: loan.frequency,
			};

			totalObjs.push(totalObj)
			minimumObjs.push(minimumObj);
			extraObjs.push(extraObj);

			return null
		})

		// @TODO make this a UI option
		const interestSort = "desc"
		loans.sort((firstEl: LoanSingleObject, secondEl: LoanSingleObject) => {
			if (firstEl.interest_rate === secondEl.interest_rate) {
				return 0
			}

			if (firstEl.interest_rate > secondEl.interest_rate) {
				return interestSort === "asc" ? 1 : -1
			}

			return interestSort === "asc" ? -1 : 1
		})

		return (
			<ConnectedSectionWrapper
				periodToggle={true}
				title="Loans"
				render={() => (
					<>
						<div className="level">
							<TotalLoansLevel label="Minimum Loan Payments" color="warning-dark" totalLoans={getPeriodSum( minimumObjs, this.props.period )}/>
							<TotalLoansLevel label="Extra Loan Payments" color="info-dark" totalLoans={getPeriodSum( extraObjs, this.props.period )}/>
							<TotalLoansLevel totalLoans={getPeriodSum( totalObjs, this.props.period )}/>
						</div>

						<div className="add-loan has-text-right">
							<Link className="button is-primary" to="/loans/add">
								<span className="icon">
									<FontAwesomeIcon icon="plus"/>
								</span>
								<span>Add Loan</span>
							</Link>
						</div>

						<div className="loans-list section has-stacked-panels">
							<div className='loans columns legend is-mobile'>
								<strong className="column is-1 is-hidden-mobile">Date</strong>
								<strong className="column is-4">Name</strong>
								<strong className="column is-hidden-mobile">Bank Account</strong>
								<strong className="column has-text-right">Payment</strong>
								<strong className="column has-text-right">Balance</strong>
							</div>

							{ loans.map( loan => {
								let isHidden = this.state.expandedLoan === loan.id ? '' : 'is-hidden',
									isActive = this.state.expandedLoan === loan.id ? 'is-active' : '';

								// Faking my way through these since we pass the whole objet, not parts
								const minimumObj = {
									amount: loan.minimum_payment,
									frequency: loan.frequency,
								};
								const extraObj = {
									amount: loan.extra_payment,
									frequency: loan.frequency,
								};
								const totalObj = {
									amount: parseFloat(loan.extra_payment) + parseFloat(loan.minimum_payment),
									frequency: loan.frequency,
								};

								const totalPaymentsLeft = CalculateNumberOfPaymentsLeft(loan),
									savedInterest = CalculateSavedInterestFromExtraPayments(loan);

								// So that flow knows the proper type here
								loan = (loan: LoanSingleObject)

								return (
									<div className={`panel ${isActive}`} key={loan.id}>
										<div className="panel-heading has-interaction is-size-6 has-text-weight-normal" onClick={() => { this.handleToggle( loan.id )}}>
											<div className='columns is-mobile'>
												<span className="column is-1 is-hidden-mobile">-</span>
												<div className="column is-4">
													{ loan.name }
													<sub className="is-block is-size-7">Interest Rate: { formatPercent(parseFloat(loan.interest_rate))}</sub>
													<sub className="is-block is-size-7">Projected Payments Left: { totalPaymentsLeft }</sub>
													<sub className="is-block is-size-7">Projected Interest Savings: {formatUSD(savedInterest)}</sub>
												</div>
												<span className="column is-hidden-mobile">{ this.getBankAccountName( parseInt(loan.bank_account_id, 10) ) }</span>
												<div className="column has-text-right">
													{(loan.autopay && (
														<span className="icon is-small has-text-grey-light"><FontAwesomeIcon size="xs" icon="stopwatch"/></span>
													))|| ""}
													{formatUSD(getPeriodEquivalent(totalObj, this.props.period))}
													<sub className="is-block is-size-7">Minimum: {formatUSD(getPeriodEquivalent(minimumObj, this.props.period))}</sub>
													<sub className="is-block is-size-7">Extra: {formatUSD(getPeriodEquivalent(extraObj, this.props.period))}</sub>
												</div>
												<span className="column has-text-right">{ formatUSD( loan.amount || 0 ) }</span>
											</div>
										</div>

										{loan.notes.length > 0 && (
											<div className={`panel-block ${isHidden} ${isActive}`}>
												<p className="content">
													{nl2br(loan.notes)}
												</p>
											</div>
										)}

										<div className={`panel-block ${isHidden} ${isActive}`}>
											<div className="columns">
												<div className="column">
													<Link className="button is-small" to={`/loans/edit/${loan.id}`}>
														<span className="icon">
															<FontAwesomeIcon icon={'edit'}/>
														</span>
														<span>Edit Loan</span>
													</Link>
												</div>
												<div className="column">
													<DeleteConfirm className={"is-centered"} buttonText="Delete Loan" deleteMethod={() => { this.props.loansDeleteLoan( loan.id ) }} />
												</div>
											</div>
										</div>
									</div>
								)
							})}

							<div className="columns is-mobile">
								<div className="column is-5"></div>
								<div className="column is-hidden-mobile"></div>
								<div className="column is-hidden-mobile"></div>
								<div className="column"></div>
								<div className="column has-text-right">
									<strong>{ formatUSD(getPeriodSum(totalObjs, this.props.period))}</strong>
								</div>
								<div className="column has-text-right">
									<strong>{ formatUSD( this.getTotalLoanBalance() ) }</strong>
								</div>
							</div>

						</div>
					</>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state : State ) => ( {
	loans: state.loans,
	bankAccounts: state.bankAccounts,
	period: state.app.period,
} );

export const ConnectedLoans = connect( mapStateToProps, mapDispatchToProps )( Loans );
