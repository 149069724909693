import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	AUTH_SET_USERNAME,
	AUTH_SET_PASSWORD,
	AUTH_LOGIN_SUCCESS, AUTH_LOGIN_BEGIN, AUTH_LOGIN_FAILURE,
} from '../actions/auth';
import { setLocalStorageAuth } from '../utils/authUtils';

export const defaultAuthState = {
	loginForm: {
		username: '',
		password: '',
	},
	accessToken: '',
	accessTokenExpiration: 0,
	loading: false,
	loginError: false,
};

export const auth = createReducer( defaultAuthState, {
	[AUTH_SET_USERNAME]( state, action ) {
		return dotProp.set( state, 'loginForm.username', action.payload.username );
	},
	[AUTH_SET_PASSWORD]( state, action ) {
		return dotProp.set( state, 'loginForm.password', action.payload.password );
	},
	[AUTH_LOGIN_BEGIN]( state, action ) {
		state = dotProp.set( state, 'loginError', false );
		return dotProp.set( state, 'loading', true );
	},
	[AUTH_LOGIN_FAILURE]( state, action ) {
		state = dotProp.set( state, 'loginError', true );
		return dotProp.set( state, 'loading', false );
	},
	[AUTH_LOGIN_SUCCESS]( state, action ) {
		// JS has all this in MS, so * 1000
		let tokenExpiration = Date.now() + ( 1000 * action.payload.expires_in );

		state = dotProp.set( state, 'loginError', false );
		state = dotProp.set( state, 'accessToken', action.payload.access_token );
		state = dotProp.set( state, 'accessTokenExpiration', tokenExpiration );

		setLocalStorageAuth( action.payload.access_token, tokenExpiration );

		return state;
	},
});
