import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../utils/authUtils';

export const LoggedOutRedirectRoute = ({ component: Component, loggedOutRedirect, auth, ...rest }) => (
	<Route
		{...rest}
		render={props => (
			isLoggedIn( auth )
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: loggedOutRedirect,
						state: { from: props.location }
					}}
				/>
		)}
	/>
);
