import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	PROFILE_ACCEPT_INVITE_SUCCESS, PROFILE_DECLINE_INVITE_SUCCESS,
	PROFILE_GET_INVITES_SUCCESS,
	PROFILE_GET_PROFILE_SUCCESS
} from "../actions/profile";
import { objectDataToState } from '../utils/stateHelpers';

export const defaultProfileState = {
	loaded: false,
	name: '',
	email: '',
	invites: {
		objects: {},
		ids: [],
	},
	inviteAccepted: false,
};

export const profile = createReducer( defaultProfileState, {
	[PROFILE_GET_PROFILE_SUCCESS]( state, action ) {
		state = dotProp.set( state, 'name', action.payload.name );
		state = dotProp.set( state, 'email', action.payload.email );
		state = dotProp.set( state, 'loaded', true );

		return state;
	},
	[PROFILE_GET_INVITES_SUCCESS]( state, action ) {
		state = dotProp.set( state, 'inviteAccepted', false );
		state = dotProp.set( state, 'invites', objectDataToState( state.invites, action ) );

		return state;
	},
	[PROFILE_ACCEPT_INVITE_SUCCESS]( state, action ) {
		state = dotProp.set( state, 'inviteAccepted', true );

		return state;
	},
	[PROFILE_DECLINE_INVITE_SUCCESS]( state, action ) {
		return dotProp.set( state, 'invites', defaultProfileState.invites );
	}
});
