// @flow

import { Dispatch } from 'redux';
import type { GetState } from '../types/actions';
import { get, post, del } from '../utils/rsaaHelpers';
import { isLoggedIn } from '../utils/authUtils';
import history from '../history';
import {ThunkAction} from "redux-thunk"

export const ACCOUNTS_GET_ACCOUNTS_BEGIN = 'ACCOUNTS_GET_ACCOUNTS_BEGIN';
export const ACCOUNTS_GET_ACCOUNTS_SUCCESS = 'ACCOUNTS_GET_ACCOUNTS_SUCCESS';
export const ACCOUNTS_GET_ACCOUNTS_FAILURE = 'ACCOUNTS_GET_ACCOUNTS_FAILURE';

export const ACCOUNTS_ADD_ACCOUNT_BEGIN = 'ACCOUNTS_ADD_ACCOUNT_BEGIN';
export const ACCOUNTS_ADD_ACCOUNT_SUCCESS = 'ACCOUNTS_ADD_ACCOUNT_SUCCESS';
export const ACCOUNTS_ADD_ACCOUNT_FAILURE = 'ACCOUNTS_ADD_ACCOUNT_FAILURE';

export const ACCOUNTS_GET_INVITES_BEGIN = 'ACCOUNTS_GET_INVITES_BEGIN';
export const ACCOUNTS_GET_INVITES_SUCCESS = 'ACCOUNTS_GET_INVITES_SUCCESS';
export const ACCOUNTS_GET_INVITES_FAILURE = 'ACCOUNTS_GET_INVITES_FAILURE';

export const ACCOUNTS_DELETE_INVITE_BEGIN = 'ACCOUNTS_DELETE_INVITE_BEGIN';
export const ACCOUNTS_DELETE_INVITE_SUCCESS = 'ACCOUNTS_DELETE_INVITE_SUCCESS';
export const ACCOUNTS_DELETE_INVITE_FAILURE = 'ACCOUNTS_DELETE_INVITE_FAILURE';

export const ACCOUNTS_SET_ACTIVE_ACCOUNT = 'ACCOUNTS_SET_ACTIVE_ACCOUNT';
export const ACCOUNTS_SWITCH_ACCOUNT = 'ACCOUNTS_SWITCH_ACCOUNT';

export const ACCOUNTS_CLONE_ACCOUNT_BEGIN = 'ACCOUNTS_CLONE_ACCOUNT_BEGIN';
export const ACCOUNTS_CLONE_ACCOUNT_SUCCESS = 'ACCOUNTS_CLONE_ACCOUNT_SUCCESS';
export const ACCOUNTS_CLONE_ACCOUNT_FAILURE = 'ACCOUNTS_CLONE_ACCOUNT_FAILURE';

export const ACCOUNTS_DELETE_ACCOUNT_BEGIN = 'ACCOUNTS_DELETE_ACCOUNT_BEGIN';
export const ACCOUNTS_DELETE_ACCOUNT_SUCCESS = 'ACCOUNTS_DELETE_ACCOUNT_SUCCESS';
export const ACCOUNTS_DELETE_ACCOUNT_FAILURE = 'ACCOUNTS_DELETE_ACCOUNT_FAILURE';

// Tracks fields as they are entered
export const ACCOUNTS_SET_ADD_FIELD = 'ACCOUNTS_SET_ADD_FIELD';

export const accountsGetAccounts = ( page: ?number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { auth, accounts } = getState();

	if ( isLoggedIn( auth ) && accounts.loaded === false ) {
		dispatch( get(
			'/accounts',
			[ ACCOUNTS_GET_ACCOUNTS_BEGIN, ACCOUNTS_GET_ACCOUNTS_SUCCESS, ACCOUNTS_GET_ACCOUNTS_FAILURE ]
		) ).then(() => {
			const { accounts } = getState();

			if ( accounts.ids.length === 1 ) {
				dispatch( accountsSetActiveAccount( accounts.objects[ accounts.ids[0] ].id ) );
			}
		});
	}
};

export const accountsAddAccount = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { add } = getState().accounts;

	dispatch( post(
		`/accounts`,
		[ ACCOUNTS_ADD_ACCOUNT_BEGIN, ACCOUNTS_ADD_ACCOUNT_SUCCESS, ACCOUNTS_ADD_ACCOUNT_FAILURE ],
		add
	)).then(() => {
		const { accounts } = getState();

		if ( accounts.ids.length === 1 ) {
			// @todo doesn't work because we don't add or reload accounts
			dispatch( accountsSetActiveAccount( accounts.objects[ accounts.ids[0] ].id ) );
		}

		if ( Object.keys( accounts.errors ).length === 0 ) {
			history.push( '/summary' );
		}
	});
};

export const accountsCloneAccount = ( name: string ) : ThunkAction => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	return dispatch( post(
		`/accounts/${activeAccount}/clone`,
		[ACCOUNTS_CLONE_ACCOUNT_BEGIN, ACCOUNTS_CLONE_ACCOUNT_SUCCESS, ACCOUNTS_CLONE_ACCOUNT_FAILURE],
		{ name }
	));
};

export const accountsDeleteAccount = ( activeAccount: number ) : ThunkAction => ( dispatch: Dispatch, getState: GetState ) => {
	return dispatch( del(
		`/accounts/${activeAccount}`,
		[ACCOUNTS_DELETE_ACCOUNT_BEGIN, ACCOUNTS_DELETE_ACCOUNT_SUCCESS, ACCOUNTS_DELETE_ACCOUNT_FAILURE],
		{}
	));
};

export const accountsSetField = ( field: string, value: any ) => {
	return {
		type: ACCOUNTS_SET_ADD_FIELD,
		payload: {
			field,
			value
		}
	}
};

export const accountsSetActiveAccount = ( accountID: number ) => {
	return {
		type: ACCOUNTS_SET_ACTIVE_ACCOUNT,
		payload: {
			id: accountID
		}
	}
};

export const accountsSwitchAccount = () => ( dispatch: Dispatch, getState: GetState ) => {
	// clear the activeAccount and the account picker screen will come back up
	dispatch( accountsSetActiveAccount( 0 ) );

	// Fire an action so we can do anything else we might need to do (clear state, etc)
	dispatch({
		type: ACCOUNTS_SWITCH_ACCOUNT,
	});

	history.push( '/summary' );
};

export const accountsGetInvites = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( get(
		`/accounts/${activeAccount}/invites`,
		[ ACCOUNTS_GET_INVITES_BEGIN, ACCOUNTS_GET_INVITES_SUCCESS, ACCOUNTS_GET_INVITES_FAILURE ]
	));
};

export const accountsRevokeInvite = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	dispatch( del(
		`/accounts/${activeAccount}/invites/${id}`,
		[ ACCOUNTS_DELETE_INVITE_BEGIN, ACCOUNTS_DELETE_INVITE_SUCCESS, ACCOUNTS_DELETE_INVITE_FAILURE ],
		{},
		{ id }
	));
};


