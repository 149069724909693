import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	EXPENSES_ADD_EXPENSE_BEGIN,
	EXPENSES_ADD_EXPENSE_FAILURE,
	EXPENSES_ADD_EXPENSE_SUCCESS,
	EXPENSES_ADD_SPLIT,
	EXPENSES_DELETE_EXPENSE_SUCCESS, EXPENSES_DELETE_SPLIT,
	EXPENSES_EDIT_EXPENSE_BEGIN, EXPENSES_EDIT_EXPENSE_FAILURE,
	EXPENSES_EDIT_EXPENSE_SUCCESS,
	EXPENSES_EDIT_SET_STATE,
	EXPENSES_GET_EXPENSES_SUCCESS,
	EXPENSES_SET_ADD_FIELD,
} from '../actions/expenses';
import {
	addEditBeginState,
	addEditFailedState, addEditSuccessState,
	arrayAdd,
	deleteSingleObjectFromState,
	objectDataToState, setEditStateHelper
} from '../utils/stateHelpers';
import { defaultObjectState } from './common/objectState';
import { ACCOUNTS_SWITCH_ACCOUNT } from '../actions/accounts';

let defaultSplitState = {
	name: '',
	amount: 0.0000,
};

let defaultExpenseState = {
	...defaultObjectState,
	add: {
		name: '',
		amount: 0.0000,
		frequency: '',
		date: '',
		autopay: false,
		bank_account_id: 0,
		notes: '',
		children: []
	}
};

export const expenses = createReducer( defaultExpenseState, {
	[EXPENSES_GET_EXPENSES_SUCCESS]( state, action ) {
		return objectDataToState( state, action );
	},
	[EXPENSES_SET_ADD_FIELD]( state, action ) {
		return dotProp.set( state, `add.${action.payload.field}`, action.payload.value );
	},
	[EXPENSES_ADD_SPLIT]( state, action ) {
		return arrayAdd( state, 'add.children', [defaultSplitState] );
	},
	[EXPENSES_DELETE_SPLIT]( state, action ) {
		state = dotProp.delete( state, `add.children.${action.payload.index}` );

		// Rather than try and reset the indexes and such, we'll just clear all child errors for now
		state = dotProp.set( state, 'errors.children', {} );

		return state;
	},
	[EXPENSES_ADD_EXPENSE_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[EXPENSES_ADD_EXPENSE_SUCCESS]( state, action ) {
		return addEditSuccessState( state, action, defaultExpenseState );
	},
	[EXPENSES_ADD_EXPENSE_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[EXPENSES_EDIT_SET_STATE]( state, action ) {
		return setEditStateHelper( state, action, defaultExpenseState );
	},
	[EXPENSES_EDIT_EXPENSE_BEGIN]( state, action ) {
		return addEditBeginState( state );
	},
	[EXPENSES_EDIT_EXPENSE_SUCCESS]( state, action ) {
		state = addEditSuccessState( state, action, defaultExpenseState );

		// Since there is whackyness with the children right now (we end up with new AND old children in state, since we delete them server side), resetting this for now
		state = dotProp.set( state, 'objects', defaultExpenseState.objects );
		state = dotProp.set( state, 'ids', defaultExpenseState.ids );

		return state;
	},
	[EXPENSES_EDIT_EXPENSE_FAILURE]( state, action ) {
		return addEditFailedState( state, action );
	},
	[EXPENSES_DELETE_EXPENSE_SUCCESS]( state, action ) {
		// Find all children, and delete them first
		Object.values( state.objects )
			.filter( item => parseInt( item.parent, 10 ) === parseInt( action.meta.id, 10 ) )
			.forEach( item => {
				state = deleteSingleObjectFromState( state, item.id );
			});

		return deleteSingleObjectFromState( state, action.meta.id );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultExpenseState;
	}
});
