// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type { LoansAddState, State } from '../../types/state';
import { loansAddLoan } from '../../actions/loans';
import { ConnectedSectionWrapper } from '../shared/section-wrapper';
import { ConnectedLoanForm } from './form';

type Props = {
	addState: LoansAddState;
	adding: boolean;
	errors: string[];
	loansAddLoan: typeof loansAddLoan;
};

class AddLoan extends Component<Props> {

	render() {
		return (
			<ConnectedSectionWrapper
				title="Add Loan"
				render={() => (
					<ConnectedLoanForm
						addState={this.props.addState}
						adding={this.props.adding}
						errors={this.props.errors}
						saveCallback={this.props.loansAddLoan}
					/>
				)}
			/>
		)
	}

}

const mapStateToProps = ( state: State ) => ({
	addState: state.loans.add,
	adding: state.loans.adding,
	errors: state.loans.errors,
});

export const ConnectedAddLoan = connect( mapStateToProps, mapDispatchToProps )( AddLoan );
