import { AUTH_IS_EXPIRED, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_FAILURE, AUTH_LOGOUT_SUCCESS } from "../actions/auth";
import { clearLocalStorageAuth } from './authUtils';
import { clearLocalStorageActiveAccount } from './accountUtils';
import { clearLocalStorageActiveBudget } from './budgetUtils';

export default function createReducer( initialState, handlers ) {

	// So that we get a deep clone and can reset on logout
	let initialStateClone = JSON.parse( JSON.stringify( initialState ) );

	return function reducer( state = initialState, action ) {
		if ( handlers.hasOwnProperty( action.type ) ) {
			return handlers[ action.type ]( state, action )
		} else if ( [ AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE, AUTH_IS_EXPIRED ].indexOf( action.type ) !== -1 ) {
			clearLocalStorageAuth();
			clearLocalStorageActiveAccount();
			clearLocalStorageActiveBudget();
			return initialStateClone;
		} else if ( AUTH_LOGIN_SUCCESS === action.type ) {
			return initialStateClone;
		} else {
			return state;
		}

	}

}
