// @flow

import { Dispatch } from 'redux';
import type { GetState } from '../types/actions';
import { get, post } from '../utils/rsaaHelpers';
import history from '../history';

export const PROFILE_GET_PROFILE_BEGIN = 'PROFILE_GET_PROFILE_BEGIN';
export const PROFILE_GET_PROFILE_SUCCESS = 'PROFILE_GET_PROFILE_SUCCESS';
export const PROFILE_GET_PROFILE_FAILURE = 'PROFILE_GET_PROFILE_FAILURE';

export const PROFILE_GET_INVITES_BEGIN = 'PROFILE_GET_INVITES_BEGIN';
export const PROFILE_GET_INVITES_SUCCESS = 'PROFILE_GET_INVITES_SUCCESS';
export const PROFILE_GET_INVITES_FAILURE = 'PROFILE_GET_INVITES_FAILURE';

export const PROFILE_ACCEPT_INVITE_BEGIN = 'PROFILE_ACCEPT_INVITE_BEGIN';
export const PROFILE_ACCEPT_INVITE_SUCCESS = 'PROFILE_ACCEPT_INVITE_SUCCESS';
export const PROFILE_ACCEPT_INVITE_FAILURE = 'PROFILE_ACCEPT_INVITE_FAILURE';

export const PROFILE_DECLINE_INVITE_BEGIN = 'PROFILE_DECLINE_INVITE_BEGIN';
export const PROFILE_DECLINE_INVITE_SUCCESS = 'PROFILE_DECLINE_INVITE_SUCCESS';
export const PROFILE_DECLINE_INVITE_FAILURE = 'PROFILE_DECLINE_INVITE_FAILURE';

// @todo get rid of this (maybe?) - instead use users state + auth current user
export const profileGetProfile = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { profile } = getState();

	if ( profile.loaded === false ) {
		dispatch( get(
			`/users/me`,
			[ PROFILE_GET_PROFILE_BEGIN, PROFILE_GET_PROFILE_SUCCESS, PROFILE_GET_PROFILE_FAILURE ]
		) );
	}
};

export const profileGetInvites = () => ( dispatch: Dispatch, getState: GetState ) => {
	dispatch( get(
		'/users/me/invites',
		[ PROFILE_GET_INVITES_BEGIN, PROFILE_GET_INVITES_SUCCESS, PROFILE_GET_INVITES_FAILURE ]
	) );
};

export const profileAcceptInvite = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	dispatch( post(
		`/users/me/invites/accept`,
		[ PROFILE_ACCEPT_INVITE_BEGIN, PROFILE_ACCEPT_INVITE_SUCCESS, PROFILE_ACCEPT_INVITE_FAILURE ],
		{ id }
	) ).then( () => {
		history.push( '/profile' );
	});
};

export const profileDeclineInvite = ( id: number ) => ( dispatch: Dispatch, getState: GetState ) => {
	dispatch( post(
		`/users/me/invites/decline`,
		[ PROFILE_DECLINE_INVITE_BEGIN, PROFILE_DECLINE_INVITE_SUCCESS, PROFILE_DECLINE_INVITE_FAILURE ],
		{ id }
	) );
};
