// @flow

import {Dispatch} from 'redux';
import type {GetState} from '../types/actions';
import { get, post } from '../utils/rsaaHelpers';
import {ThunkAction} from "redux-thunk";

export const USERS_GET_USERS_BEGIN = 'USERS_GET_USERS_BEGIN';
export const USERS_GET_USERS_SUCCESS = 'USERS_GET_USERS_SUCCESS';
export const USERS_GET_USERS_FAILURE = 'USERS_GET_USERS_FAILURE';

export const USERS_INVITE_USER_BEGIN = 'USERS_INVITE_USER_BEGIN';
export const USERS_INVITE_USER_SUCCESS = 'USERS_INVITE_USER_SUCCESS';
export const USERS_INVITE_USER_FAILURE = 'USERS_INVITE_USER_FAILURE';

export const usersGetUsers = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { accounts } = getState();

	dispatch( get(
		`/accounts/${accounts.activeAccount}/users`,
		[ USERS_GET_USERS_BEGIN, USERS_GET_USERS_SUCCESS, USERS_GET_USERS_FAILURE ]
	) );
};

export const usersInviteUser = ( email: string ) : ThunkAction => ( dispatch: Dispatch, getState: GetState ) => {
	const { activeAccount } = getState().accounts;

	return dispatch( post(
		`/accounts/${activeAccount}/invites`,
		[USERS_INVITE_USER_BEGIN, USERS_INVITE_USER_SUCCESS, USERS_INVITE_USER_FAILURE ],
		{ email }
	));
};
