// @flow

import React, { Component } from 'react';
import { formatUSD } from '../../utils/formattingHelpers';

type Props = {
	totalExpenses: number;
};

export class TotalExpensesLevel extends Component<Props> {

	render() {
		let { totalExpenses } = this.props;

		return (
			<div className="level-item has-text-centered">
				<div>
					<p className="title has-text-danger-dark is-size-4-touch">{ formatUSD( totalExpenses ) }</p>
					<p className="heading">Total Expenses</p>
				</div>
			</div>
		)
	}

}
