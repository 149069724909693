// @flow

export const activeAccountLocalStorageKey = 'budgetActiveAccount';

export const setLocalStorageActiveAccount = ( accountID: number ) => {
	if ( window && window.localStorage ) {
		window.localStorage.setItem( activeAccountLocalStorageKey, accountID );
	}
};

export const getLocalStorageActiveAccount = () : number => {
	let activeAccount = 0;

	if ( window && window.localStorage ) {
		activeAccount = window.localStorage.getItem( activeAccountLocalStorageKey );
	}

	return activeAccount;
};

export const clearLocalStorageActiveAccount = () => {
	if ( window && window.localStorage ) {
		window.localStorage.removeItem( activeAccountLocalStorageKey );
	}
};
