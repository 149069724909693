// @flow

//import { Dispatch } from 'redux';

import type {Action, GetState} from '../types/actions';
import {Dispatch} from "redux";

export const APP_SET_PERIOD = 'APP_SET_PERIOD';

export const APP_OPEN_MENU = 'APP_OPEN_MENU';
export const APP_CLOSE_MENU = 'APP_CLOSE_MENU';

export const appSetPeriod = ( period: string ) : Action => {
	return {
		type: APP_SET_PERIOD,
		payload: {
			period
		}
	}
};

export const appToggleMenu = () => ( dispatch: Dispatch, getState: GetState ) => {
	const { menuOpen } = getState().app;

	return menuOpen === true ? dispatch( appCloseMenu() ) : dispatch( appOpenMenu() );
};

export const appOpenMenu = () : Action => {
	return {
		type: APP_OPEN_MENU
	}
};

export const appCloseMenu = () : Action => {
	return {
		type: APP_CLOSE_MENU
	}
};
