import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../utils/authUtils';
import { ConnectedAccountSwitcher } from '../accounts/switcher';
import { Link } from 'react-router-dom';
import { ConnectedSectionWrapper } from "../shared/section-wrapper";

export const PrivateRoute = ({ component: Component, auth, accounts, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if ( isLoggedIn( auth ) ) {
				if ( accounts.activeAccount > 0 ) {
					return (
						<Component {...props} />
					)
				} else {
					if ( true === accounts.loaded ) {
						if ( accounts.ids.length > 0 ) {
							return (
								<ConnectedAccountSwitcher />
							)
						} else {
							return (
								<ConnectedSectionWrapper
									title="No Accounts"
									render={() => (
										<>
											<p className="content has-text-centered">
												You aren't added to any accounts. You'll need to add a new account or have an account admin add you to their account.
											</p>

											<p className="content has-text-centered">
												You can view any account invitations <Link to={"/profile/invites"}>here</Link>.
											</p>

											<p className="content has-text-centered">
												<Link className={"button is-primary"} to={'/accounts/add'}>Add new account</Link>
											</p>
										</>
									)}
								/>
							)
						}
					} else {
						return (
							<ConnectedSectionWrapper
								title="Loading Accounts"
								render={()=>(
									<div className="content has-text-centered">
										<p>Please wait while your accounts are loaded.</p>
									</div>
								)}
							/>
						)
					}
				}

			} else {
				return (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location }
						}}
					/>
				)
			}
		}}
	/>
);
