// @flow

import type {
	ExpenseCollection,
	ExpenseFrequencyGroups,
	ExpenseSingleObject
} from '../types/objects';
import { ORDERED_FREQUENCIES } from './frequencyHelpers';

/**
 * Groups expenses into parent/child relationships
 *
 * @param expenseItems
 * @returns {Array}
 */
export const organizeExpenses = ( expenseItems: ExpenseSingleObject[] ): ExpenseCollection => {
	let expenses = [];

	// So we can insert objects easier later
	let parentRef = {};

	expenseItems.map( item => {
		let parent = parseInt( item.parent, 10 );
		let insert = {
			...item,
			children: [],
		};

		0 === parent ?
			expenses.push( insert ) :
			parentRef[ parent ]['children'].push( insert ); // Yay for references

		parentRef[ item.id ] = insert;

		return item;
	} );

	return expenses;
};

export const groupExpensesByFrequency = ( collection: ExpenseCollection ) : ExpenseFrequencyGroups => {
	let expenseFrequencyGroups = {};

	// Use the exported constant of all of the frequencies so we automatically pick up any new ones in the future
	ORDERED_FREQUENCIES.forEach( frequency => {
		expenseFrequencyGroups[ frequency ] = [];
	});

	collection.forEach( item => {
		expenseFrequencyGroups[ item.frequency ].push( item );
	});

	let expenseFrequencyCollection = [];
	for ( let frequency in expenseFrequencyGroups ) {
		if ( expenseFrequencyGroups[ frequency ].length > 0 ) {
			expenseFrequencyCollection.push({
				frequency,
				collection: expenseFrequencyGroups[ frequency ],
			})
		}
	}

	return expenseFrequencyCollection;
};

export const orderExpensesByDate = ( collection: ExpenseCollection ) : ExpenseCollection => {
	return collection.sort( function( a, b ) {
		if ( a.date.length === 0 ) {
			return 1;
		}

		if ( a.date.indexOf( '-' ) !== -1 || b.date.indexOf( '-' ) !== -1 ) {
			let aparts = a.date.split( '-' ).map( val => parseInt( val, 10 ) );
			let bparts = b.date.split( '-' ).map( val => parseInt( val, 10 ) );

			if ( aparts[0] === bparts[0] ) {
				return aparts[1] > bparts[1] ? 1 : -1;
			}

			return aparts[0] > bparts[0] ? 1 : -1;
		} else {
			let adate = parseInt( a.date, 10 ),
				bdate = parseInt( b.date, 10 );

			return adate > bdate ? 1 : -1;
		}
	});
};

export const orderExpenseGroupsByDate = ( groups: ExpenseFrequencyGroups ) : ExpenseFrequencyGroups => {
	Object.keys( groups ).forEach( key => {
		orderExpensesByDate( groups[ key ].collection );
	});

	return groups;
};

type SortableObject = {
	name: string,
	id: number,
	[key: string]: ?any
}
export const sortObjectsByName = ( objects: SortableObject[] ) : SortableObject[] => {
	return objects.sort( ( a, b ) => {
		return a.name.localeCompare( b.name );
	})
};

type CastableObject = {
	id: number,
	name: string,
};
export const objectToArray = ( object: { [key: string]: CastableObject } ) : CastableObject[] => {
	// $FlowFixMe
	return Object.values( object );
};
