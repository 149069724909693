import createReducer from '../utils/createReducer';
import dotProp from 'dot-prop-immutable';
import {
	USERS_GET_USERS_SUCCESS,
	USERS_INVITE_USER_BEGIN,
	USERS_INVITE_USER_FAILURE,
	USERS_INVITE_USER_SUCCESS
} from '../actions/users';
import { addEditBeginState, addEditFailedState, objectDataToState } from "../utils/stateHelpers";
import {ACCOUNTS_SWITCH_ACCOUNT} from "../actions/accounts";

export const defaultUsersState = {
	objects: {},
	ids: [],
	adding: false,
	errors: {},
};

export const users = createReducer( defaultUsersState, {
	[USERS_GET_USERS_SUCCESS]( state, action ) {
		return objectDataToState( state, action );
	},
	[ACCOUNTS_SWITCH_ACCOUNT]( state, action ) {
		return defaultUsersState;
	},
	[USERS_INVITE_USER_BEGIN]( state, action ) {
		state = addEditBeginState( state, action );

		return state;
	},
	[USERS_INVITE_USER_SUCCESS]( state, action ) {
		state = dotProp.set( state, 'adding', false );

		return state;
	},
	[USERS_INVITE_USER_FAILURE](state, action ) {
		state = addEditFailedState( state, action );

		return state;
	}
});
