// @flow

import React, { Component } from 'react';
import { ConnectedSectionWrapper } from './shared/section-wrapper';

export class NotFound extends Component<void> {

	render() {
		return (
			<ConnectedSectionWrapper
				title="Not Found!"
				render={ () => '' }
			/>
		)
	}

}
