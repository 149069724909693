// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../utils/dispatchToProps';
import type {State, UsersState} from "../../types/state";
import {returnSubsetById} from "../../utils/stateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {usersGetUsers} from "../../actions/users";

type Props = {
	users: UsersState;
	usersGetUsers: typeof usersGetUsers;
};

class UsersList extends Component<Props> {

	componentWillMount() {
		this.props.usersGetUsers();
	}

	render() {
		const users = returnSubsetById( this.props.users.ids, this.props.users.objects );

		return users.map( user => (
			<div className="box" key={user.id}>
				<span className="icon">
					<FontAwesomeIcon icon="user" />
				</span>
				<span>{user.name}</span>
			</div>
		))
	}

}

const mapStateToProps = ( state: State ) => ({
	users: state.users,
});

export const ConnectedUsersList = connect( mapStateToProps, mapDispatchToProps )( UsersList );
